import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SButtom, STheme, SLoad, SMath, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { DinamicTable } from 'servisofts-table'

export default class balance_usuario extends Component {
    // params = SNavigation.getAllParams();
    async loadData() {
        await SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/billetera/balance_usuarios.pgsql"
        }).then(resp => {
            let keys = [...new Set(Object.values(resp.data).map(a => a.key_cliente).filter(key => key !== null))];
            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                Object.values(resp.data).map(a => {
                    a.usuario = e2?.data[a.key_cliente]?.usuario ?? {}
                })
                return resp.data;
            }).catch(e2 => {
                SPopup.alert(e2.error)
            })
        })
    }

    render() {
        return <SPage title={'Balance Billetera Usuario'} disableScroll>
            <SView col={"xs-12"} flex>
                <DinamicTable
                    loadInitialState={async () => {
                        let filters = [];
                        // filters.push({
                        //     "col": "cliente",
                        //     "type": "string",
                        //     "operator": "contains",
                        //     "value": clientResp?.data?.descripcion
                        // });

                        return {
                            "filters": filters,
                            "sorters": [
                                {
                                    "key": "monto_billetera",
                                    "order": "desc",
                                    "type": "number"
                                }
                            ],
                        }
                    }}

                    loadData={this.loadData}
                    keyExtractor={(e) => e.key_cliente}
                    colors={{
                        text: STheme.color.gray,
                        border: STheme.color.card,
                        header: STheme.color.barColor,
                        background: STheme.color.background,
                        card: STheme.color.card
                    }}
                    cellStyle={{
                        borderWidth: 0,
                    }}
                    textStyle={{
                        fontSize: 12,
                    }}
                >

                    <DinamicTable.Col key={"usuario"} label='Usuario' width={300}
                        data={e => 
                            e.usuario
                        }
                        customComponent={e =>
                            e ? <SText>`${e?.Nombres} ${e?.Apellidos}`</SText> : "Usuario eliminado"
                        }
                    />

                    <DinamicTable.Col key={"monto_billetera"} label='Monto Billetera' width={100}
                        cellStyle={{ alignItems: "center" }}
                        textStyle={{ textAlign: "center" }}
                        data={e => `${e.total_monto}`}
                    />
                </DinamicTable>
            </SView>
        </SPage>
    }
}