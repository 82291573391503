import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SLoad, SPage, SPopup, STable2, SNavigation, SMath, SDate } from 'servisofts-component'
import SSocket from 'servisofts-socket'
import { BuildCustomHeader } from '.'
import { connect } from 'react-redux'
class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.params = SNavigation.getAllParams() ?? {};
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })

        SSocket.sendPromise({
            component: "reporte",
            type: "executeFile",
            src: "query/contexto_data/medallas_asfi_nuevo.pgsql",
            params: [this.params.fecha_inicio, this.params.fecha_fin]
        }).then(e => {
            let keys = [...new Set(Object.values(e.data).map(a => a.key_usuario).filter(key => key !== null))];
            SSocket.sendPromise({
                version: "2.0",
                service: "usuario",
                component: "usuario",
                type: "getAllKeys",
                keys: keys,
            }).then(e2 => {
                Object.values(e.data).map(a => {
                    a.usuario = e2?.data[a.key_usuario]?.usuario ?? {}
                })
                this.setState({ data: e.data })
            }).catch(e2 => {
                SPopup.alert(e2.error)
            })
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }
    
    renderPremioEntregado(val) {
        return `${val.tipo_recompensa} - ${val.monto_descuento} ${val.medida_descuento}`
    }

    renderTable() {
        if (!this.state.data) return <SLoad />;
        return (
            <STable2
                header={[
                    { key: "usuario", label: "NOMBRE COMPLETO", width: 200, render: (val) => Object.keys(val).length > 0 ? `${val?.Nombres} ${val?.Apellidos}` : "Usuario eliminado" },
                    { key: "-/usuario", label: "Nro CELULAR", width: 150, center: true, render: (val) => Object.keys(val).length > 0 ? `${val?.Telefono}` : "Usuario sin celular" },
                    { key: "fecha_on", label: "FECHA DE ENTREGA", width: 150, order: "desc",center: true,render: (val) => new SDate(val, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd") },
                    { key: "lugar", label: "LUGAR DE ENTREGA", width: 150, center: true },
                    { key: "-", label: "PREMIO ENTREGADO", width: 150, center: true, render: val => this.renderPremioEntregado(val) },
                    { key: "cobrado", label: "PREMIO USADO", width: 150, center: true },
                ]}
                data={this.state.data}
            />
        );
    }

    render() {
        return <SPage
            title={`Reporte de PROMO HÉROE TAPEKE ${this.params.fecha_inicio} hasta ${this.params.fecha_fin}`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);