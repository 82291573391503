import SSocket from "servisofts-socket";
import Model from "../../Model";

type Point = {
    latitude: number;
    longitude: number;
};

interface ZonaPoligonal {
    estado: number;
    key_usuario: string;
    color: string;
    fecha_on: string;
    type: "delivery" | "funcionamiento";
    nombre: string;
    key: string;
    points: Point[];
}


export default class Actions {
    static async editar(zona: ZonaPoligonal) {
        const resp = await SSocket.sendPromise({
            component: "zona_poligonal",
            type: "editar",
            data: zona,
        })
        return resp;

    }

    static async registro(zona: ZonaPoligonal) {
        const resp = await SSocket.sendPromise({
            component: "zona_poligonal",
            type: "registro",
            data: zona,
            key_usuario: Model.usuario.Action.getKey(),
        })
        return resp;
    }

    static async sincronizar() {
        const resp = await SSocket.sendPromise({
            component: "zona_poligonal",
            type: "recalcularZonaPoligonalRestaurante",
            key_usuario: Model.usuario.Action.getKey(),
        }, 1000*60*10)
        return resp;
    }

    static async eliminar(key: string) {
        const resp = await SSocket.sendPromise({
            component: "zona_poligonal",
            type: "editar",
            key_usuario: Model.usuario.Action.getKey(),
            data: {
                key: key,
                estado: 0,
            },
        })
        return resp;

    }
    static eliminar_punto(zona: ZonaPoligonal, index: number) {
        zona.points.splice(index, 1);
    }

    static agregar_punto(zona: ZonaPoligonal, point: Point, index: number) {
        // agregar punto despues de index
        // zona.points.push(point);
        if (index >= zona.points.length) {
            zona.points.push(point);
        } else {
            zona.points.splice(index, 0, point);

        }

    }

    static calcular_centro(zona: ZonaPoligonal): Point {
        let sumLat = 0;
        let sumLng = 0;

        for (let i = 0; i < zona.points.length; i++) {
            sumLat += zona.points[i].latitude;
            sumLng += zona.points[i].longitude;
        }

        return {
            latitude: sumLat / zona.points.length,
            longitude: sumLng / zona.points.length,
        };

    }
    static escalar_hacia_el_centro(zona: ZonaPoligonal, scale: number) {
        const center = Actions.calcular_centro(zona);
        for (let i = 0; i < zona.points.length; i++) {
            const p = zona.points[i];
            const dx = p.latitude - center.latitude;
            const dy = p.longitude - center.longitude;
            zona.points[i] = {
                latitude: center.latitude + dx * scale,
                longitude: center.longitude + dy * scale,
            };
        }


    }
    static moverCentroTo(zona: ZonaPoligonal, point: Point) {
        const center = Actions.calcular_centro(zona);
        const dx = point.latitude - center.latitude;
        const dy = point.longitude - center.longitude;

        for (let i = 0; i < zona.points.length; i++) {
            const p = zona.points[i];
            zona.points[i] = {
                latitude: p.latitude + dx,
                longitude: p.longitude + dy,
            };
        }
    }
    static encontrar_arista_mas_cercano(zona: ZonaPoligonal, point: Point): number {
        if (zona.points.length < 2) return -1; // No hay suficientes puntos para formar una arista

        let minDist = Infinity;
        let closestIndex = -1;

        for (let i = 0; i <= zona.points.length - 1; i++) {
            let p1 = zona.points[i];
            let p2;
            if (i == zona.points.length - 1) {
                p2 = zona.points[0];
            } else {
                p2 = zona.points[i + 1];
            }


            // Calcular la distancia del punto al segmento
            const dist = Actions.distancia_punto_a_segmento(point, p1, p2);

            if (dist < minDist) {
                minDist = dist;
                closestIndex = i;
            }
        }

        return closestIndex; // Índice del primer punto del segmento más cercano
    }

    static encontrar_punto_mas_cercano(zona: ZonaPoligonal, point: Point) {

        let min = 999999999;
        let index = -1;

        for (let i = 0; i < zona.points.length; i++) {
            const p = zona.points[i];
            const dist = Math.sqrt((p.latitude - point.latitude) ** 2 + (p.longitude - point.longitude) ** 2);
            if (dist < min) {
                min = dist;
                index = i;
            }
        }
        return index;
    }
    static detectar_si_va_antes_o_despues(zona: ZonaPoligonal, point: Point, index: number) {
        if (index < 0 || index >= zona.points.length - 1) return false; // Manejar índice inválido

        const p1 = zona.points[index - 1];
        const p2 = zona.points[index + 1];
        const p3 = point;

        // Distancias de p1 y p2 al nuevo punto
        const dist1 = Math.sqrt((p1.latitude - p3.latitude) ** 2 + (p1.longitude - p3.longitude) ** 2);
        const dist2 = Math.sqrt((p2.latitude - p3.latitude) ** 2 + (p2.longitude - p3.longitude) ** 2);
        console.log(dist1, dist2);
        // Si está más cerca de p1 que de p2, va antes
        return dist1 < dist2;
    }

    static distancia_punto_a_segmento(p: Point, a: Point, b: Point): number {
        const A = p.latitude - a.latitude;
        const B = p.longitude - a.longitude;
        const C = b.latitude - a.latitude;
        const D = b.longitude - a.longitude;

        const dot = A * C + B * D;
        const len_sq = C * C + D * D;
        let param = -1;

        if (len_sq !== 0) param = dot / len_sq;

        let xx, yy;
        if (param < 0) {
            xx = a.latitude;
            yy = a.longitude;
        } else if (param > 1) {
            xx = b.latitude;
            yy = b.longitude;
        } else {
            xx = a.latitude + param * C;
            yy = a.longitude + param * D;
        }

        const dx = p.latitude - xx;
        const dy = p.longitude - yy;
        return Math.sqrt(dx * dx + dy * dy);
    }

    static create_triangle_by_center(center: Point, radius: number) {
        const angle = 2 * Math.PI / 3;
        const points = [];
        for (let i = 0; i < 3; i++) {
            points.push({
                latitude: center.latitude + radius * Math.cos(i * angle),
                longitude: center.longitude + radius * Math.sin(i * angle),
            });
        }
        return points;
    }
}