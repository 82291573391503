//import liraries
import React, { Component } from 'react';
import { View, Text, StyleSheet, FlatList, SectionList } from 'react-native';
import { SButtom, SHr, SIcon, SImage, SInput, SNavigation, SNotification, SPage, SPopup, SRangeSlider, SText, STheme, SThread, SUuid, SView } from 'servisofts-component';
import { SMapView } from 'servisofts-component';

import customMapStyle from './customMapStyle'
import SSocket from 'servisofts-socket';
import Model from '../../Model';
import Btn from '../../Components/Btn';
import Actions from './Actions';
import item from '../descuento/item';
import restaurante from '../restaurante';

// create a component
class index extends Component {

    constructor(props) {
        super(props);

        this.onSelect = SNavigation.getParam("onSelect");
    }
    state = {
        lotes: [],
        verRestaurantes: false,
        isTest: false,
        // select: "ee0c156a-4e70-40f0-bcce-9a528daf4a30"
        select: "",

    };

    componentDidMount() {
        SSocket.sendPromise({
            component: "zona_poligonal",
            type: "getAll",
        }).then(e => {
            this.setState({ lotes: Object.values(e.data) ?? [] })

            if (!!this.state.select) {
                new SThread(500, "hilo", true).start(() => {
                    const lote = this.state.lotes.find(e => e.key == this.state.select);
                    this.initial = JSON.parse(JSON.stringify(lote));
                    this.verLote(lote.points)
                })
            }
            console.log(e);
        }).catch(e => {
            console.error(e);
        })

        SSocket.sendPromise({
            component: "restaurante",
            type: "getAll",
        }).then(e => {
            const arr = Object.values(e.data).filter(e => e.estado == 1)
            this.setState({ restaurantes: arr })
        }).catch(e => {
            console.error(e);
        })



    }
    polilineRepaint() {
        return this.state.lotes.sort((a, b) => {
            if (!a.key_zona_poligonal && !!b.key_zona_poligonal) {
                return -1;
            }
            if (!b.key_zona_poligonal && !!a.key_zona_poligonal) {
                return 1;
            }
            if (a.key == this.state.select) {
                return 1;
            }

            if (b.key == this.state.select) {
                return -1;
            }
            return 0;
        }).map((lote) => {
            if (!lote.points) return null;
            return <SMapView.SPolygon coordinates={lote.points} strokeColor={STheme.color.text} fillOpacity={lote.type == "operacion" ? 0.3 : 0.5} fillColor={lote.color ?? STheme.color.text} strokeWidth={1}
                onPress={e => {
                    if (this.state.select == lote.key) {
                        const point = {
                            latitude: e.latLng.lat(),
                            longitude: e.latLng.lng()
                        }
                        console.log(point);
                        let index = Actions.encontrar_arista_mas_cercano(lote, point)
                        // if (Actions.detectar_si_va_antes_o_despues(lote, point, index)) {
                        //     index--;
                        // }
                        Actions.agregar_punto(lote, point, index + 1)
                        this.setState({ ...this.state })
                    } else {
                        this.selectZona(lote)
                    }


                }} />
        });

    }

    selectZona(lote) {
        if (!!this.state.select) {
            this.handleCancelSelect()

        }
        this.initial = JSON.parse(JSON.stringify(lote));
        this.setState({ select: lote.key })
    }
    renderRestaurantesMarker() {
        if (!this.state.verRestaurantes) return null
        if (!this.state.restaurantes) return null;
        return this.state.restaurantes.map((restaurante) => {
            return <SMapView.SMarker latitude={restaurante.latitude} longitude={restaurante.longitude} onPress={e => {
                SNavigation.navigate("/restaurante/profile", { pk: restaurante.key })
            }}>
                <SView width={30} height={30} center style={{
                    borderRadius: 100,
                    backgroundColor: STheme.color.card,
                    overflow: "hidden",
                    borderWidth: 1,
                    borderColor: STheme.color.text
                }}>
                    <SImage style={{
                        resizeMode: "cover"
                    }} src={SSocket.api.root + "restaurante/.128_" + restaurante.key} />
                </SView>
            </SMapView.SMarker>
        })
    }

    markerCenterRepaint() {
        let arr = [];
        const lote = this.state.lotes.find(e => e.key == this.state.select);
        if (!lote?.points) return null;
        const center = Actions.calcular_centro(lote);
        return <SMapView.SMarkerDragable latitude={center.latitude} longitude={center.longitude}
            icon={{
                path: "CIRCLE",
                scale: 7,
                fillColor: STheme.color.warning,
                fillOpacity: 0.8,
                strokeColor: STheme.color.text,
                strokeWeight: 1
            }}
            onDragEnd={e => {
                Actions.moverCentroTo(lote, e)
                this.setState({ ...this.state })
            }} />
    }
    // MARKER
    markerRepaint() {
        let arr = [];
        const lote = this.state.lotes.find(e => e.key == this.state.select);
        if (!lote?.points) return null;
        lote.points.map((point, i) => {
            // const ELM = <SMapView.SMarker latitude={point.latitude} longitude={point.longitude} >
            //     <SText>{i}</SText>
            // </SMapView.SMarker>

            const ELM = <SMapView.SMarkerDragable latitude={point.latitude} longitude={point.longitude}
                icon={{
                    path: "CIRCLE",
                    scale: 7,
                    fillColor: STheme.color.text,
                    fillOpacity: 0.8,
                    strokeColor: STheme.color.text,
                    strokeWeight: 1
                }}
                onPress={(e) => {
                    SPopup.open({
                        key: "zonepoint",
                        type: "2",
                        content: <SView style={{
                            position: "absolute",
                            width: 30,
                            height: 30,
                            backgroundColor: STheme.color.background + "99",
                            borderWidth: 1,
                            borderColor: STheme.color.card,
                            borderRadius: 8,
                            top: e.domEvent.y,
                            left: e.domEvent.x
                        }} withoutFeedback row center>
                            <SView width={24} height={24} onPress={() => {
                                Actions.eliminar_punto(lote, i);
                                this.setState({ ...this.state })
                                SPopup.close("zonepoint")
                            }}>
                                <SIcon name='Delete' />
                            </SView>
                        </SView>
                    })
                    console.log("asdasdas", e)
                }}
                onDragEnd={e => {
                    point.latitude = e.latitude;
                    point.longitude = e.longitude;
                    this.setState({ ...this.state })
                }} />
            arr.push(ELM);
        })
        return arr;

    }

    // markerRepaint() {
    //     return this.state.points.map((point,i) => {
    //         return <SMapView.SMarker latitude={point.latitude} longitude={point.longitude} >
    //             <SText>{i}</SText>
    //         </SMapView.SMarker>
    //     });
    // }

    checkPint(event) {

        SSocket.sendPromise({
            component: "zona_poligonal",
            type: "getZonasByPoint",
            latitude: event.coordinate.latitude,
            longitude: event.coordinate.longitude
        }).then(e => {
            if (e.data.length <= 0) {
                throw "No es zona"
            }

            SNotification.send({
                title: "Es zona",
                body: "Esta en una zona",
                color: STheme.color.success,
                time: 3000
            })
            console.log(e);
        }).catch(e => {
            SNotification.send({
                title: "No Zona",
                color: STheme.color.danger,
                time: 3000
            })
            console.error(e);
        })
        return;
    }

    pressLote(event) {
        console.log(event);
        return;
        // console.log(event);
        return this.checkPint(event);
        if (this.state.lotes.length <= 0) {
            this.state.lotes.push({ points: [] });
        }
        this.state.lotes[this.state.lotes.length - 1].points.push(event.coordinate)
        this.setState({ ...this.state })
    }

    crearLote() {
        this.state.lotes.push({ points: [] })
        this.setState({ ...this.state })
    }

    // eliminarLote(index, lote) {
    //     const lotes = [...this.state.lotes]; // Hacemos una copia del array
    //     lotes.splice(index, 1); // Eliminamos el elemento en la posición 'index'
    //     this.setState({ lotes }); // Actualizamos el estado con el nuevo array
    //     if (lote) {
    //         SSocket.sendPromise({
    //             component: "zona_poligonal",
    //             type: "editar",
    //             data: {
    //                 ...lote,
    //                 estado: 0
    //             }
    //         }).then(e => {
    //             // this.setState({ lotes: Object.values(e.data) })
    //             console.log(e);
    //         }).catch(e => {
    //             console.error(e);
    //         })
    //     }

    // }

    verLote(points) {
        // mover el mapa a donde esta el lote
        this.map.fitToCoordinates(points, { edgePadding: { top: -0.005, right: -0.005, bottom: -0.005, left: -0.005 } })
    }
    // guardarLote(lote) {
    //     // mover el mapa a donde esta el lote
    //     SSocket.sendPromise({
    //         component: "zona_poligonal",
    //         type: "registro",
    //         key_usuario: Model.usuario.Action.getKey(),
    //         data: {
    //             key_usuario: Model.usuario.Action.getKey(),
    //             points: lote.points,
    //         }
    //     }).then(e => {
    //         console.log(e);
    //     }).catch(e => {
    //         console.error(e);
    //     })
    // }


    handleCancelSelect() {

        const lote = this.state.lotes.find(e => e.key == this.state.select);
        if (lote.key == "nuevo") {
            this.state.lotes = this.state.lotes.filter(e => e.key != lote.key);
        }
        if (this.initial) {
            lote.points = [...this.initial.points];
            lote.nombre = this.initial.nombre;
            lote.color = this.initial.color;
        }
        this.initial = null;
        this.setState({ select: null })
    }
    renderSelectComponent() {
        const scaleFactor = 0.02
        if (!this.state.select) return null;
        const lote = this.state.lotes.find(e => e.key == this.state.select);
        if (!lote) return null;
        if (!lote.color) {
            lote.color = STheme.color.warning
        }
        return <SView col={"xs-11"} style={{
            position: "absolute",
            top: 4,
            backgroundColor: STheme.color.background,
            borderWidth: 1,
            borderColor: STheme.color.gray,
            borderRadius: 10,
            padding: 8,
            maxWidth: 400
        }} center>
            <SText bold color={STheme.color.gray}>{"MODO EDICION DE ZONA"}</SText>
            <SHr h={8} />

            <SInput height={30} style={{ textAlign: "center", fontWeight: "bold" }} key={lote.key + "a"} type='text' defaultValue={lote.nombre} placeholder={"nombre"}
                onChangeText={e => {
                    lote.nombre = e;
                    // this.setState({...this.state})
                }} />
            <SHr h={4} />
            <SInput height={30} key={lote.key + "b"} type='color' defaultValue={lote.color}
                onChangeText={e => {
                    lote.color = e;
                    // this.setState({...this.state})
                }} />
            {/* <SInput height={30} key={lote.key + "c"} defaultValue={lote.type}
                onChangeText={e => {
                    lote.type = e
                    // this.setState({...this.state})
                }} /> */}
            <SHr h={8} />
            <SView col={"xs-12"}>
                <SText fontSize={9} color={STheme.color.lightGray}>{"- Mueve los puntos para modificar la zona."}</SText>
                <SText fontSize={9} color={STheme.color.lightGray}>{"- Para agregar nuevos puntos da click dentro de la zona y entre dos puntos."}</SText>
                <SText fontSize={9} color={STheme.color.lightGray}>{"- Recuerda guardar tus cambios!"}</SText>
            </SView>
            <SHr h={8} />
            <SView row col={"xs-12"} center>
                <SText flex>{"Scale: "}</SText>
                <SView width={8} />
                <Btn flex textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.success }}
                    onPress={() => {
                        Actions.escalar_hacia_el_centro(lote, 1 - scaleFactor)
                        this.setState({ ...this.state })
                    }}>-</Btn>
                <SView width={8} />
                <Btn flex textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.success }}
                    onPress={() => {
                        Actions.escalar_hacia_el_centro(lote, 1 + scaleFactor)
                        this.setState({ ...this.state })
                    }}>+</Btn>
            </SView>
            {/* <SView center width={100} padding={4}>
                <Btn width={24} col={null} textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.success }}
                    onPress={() => {
                        Actions.escalar_hacia_el_centro(lote, 1 - scaleFactor)
                        this.setState({ ...this.state })
                    }}>U</Btn>
                <SHr h={4} />

                <SView row col={"xs-12"}>
                    <Btn width={24} col={null} textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.success }}
                        onPress={() => {
                            Actions.escalar_hacia_el_centro(lote, 1 + scaleFactor)
                            this.setState({ ...this.state })
                        }}>{"<-"}</Btn>
                    <SView flex />
                    <Btn width={24} col={null} textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.success }}
                        onPress={() => {
                            Actions.escalar_hacia_el_centro(lote, 1 + scaleFactor)
                            this.setState({ ...this.state })
                        }}>D</Btn>
                    <SView flex />
                    <Btn width={24} col={null} textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.success }}
                        onPress={() => {
                            Actions.escalar_hacia_el_centro(lote, 1 + scaleFactor)
                            this.setState({ ...this.state })
                        }}>{"->"}</Btn>
                </SView>
            </SView> */}
            <SHr h={8} />
            <SView row col={"xs-12"}>
                {!!this.onSelect ? <>
                    <Btn flex textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.card }}
                        onPress={() => {
                            this.onSelect(lote);
                            SNavigation.goBack();
                        }}
                    >Seleccionar</Btn>
                    <SView width={8} />
                </> : null}
                {!lote.key_zona_poligonal ? <>
                    <Btn flex textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.card }}
                        onPress={() => {
                            this.addNuevaZona(lote.key);
                        }}
                    >Agregar Zona</Btn>
                    <SView width={8} />
                </> : null}
                <Btn flex textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.danger }}
                    onPress={() => {
                        SPopup.confirm({
                            title: "Eliminar la zona " + lote.nombre,
                            message: "¿Estas seguro de eliminar esta zona?",
                            onPress: () => {
                                Actions.eliminar(lote.key).then(e => {
                                    this.state.lotes = this.state.lotes.filter(e => e.key != lote.key);
                                    this.setState({ select: null })
                                })
                                console.log("sadasd");
                            },

                        })
                    }}
                >Eliminar</Btn>
                <SView width={8} />
                <Btn flex textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.warning }}
                    onPress={() => {

                        this.handleCancelSelect()
                    }}
                >Cancelar</Btn>
                <SView width={8} />
                <Btn flex textStyle={{ fontSize: 12 }} height={24} style={{ backgroundColor: STheme.color.success }}
                    onPress={() => {
                        if (lote.key == "nuevo") {
                            Actions.registro(lote).then(e => {
                                this.state.lotes = this.state.lotes.filter(e => e.key != lote.key);
                                this.state.lotes.push(e.data);
                                this.setState({ select: null })
                            })

                        } else {
                            Actions.editar(lote)
                        }

                        this.setState({ select: null })
                        SPopup.alert("Recuerda sincronizar, ya que sino los cambios no se verán reflejados si hay algún descuento Tapeke en zonas")
                    }}>Guardar</Btn>
            </SView>

        </SView >
    }

    renderItemList(item) {
        const isSelect = this.state.select == item.key;
        return <SView col={"xs-12"} row center
            style={{
                padding: 4,
                backgroundColor: isSelect ? STheme.color.info + "99" : "transparent",
            }}
            onPress={() => {
                this.verLote(item.points)
                this.selectZona(item)
            }}>
            <SView style={{
                width: 16, height: 16,
                // borderWidth: 0.5,
                // borderColor: STheme.color.text,
                backgroundColor: item.color ?? STheme.color.card, borderRadius: 2
            }}></SView>
            <SView width={4} />
            <SText flex fontSize={12} bold color={STheme.color.text} numberOfLines={1}>{item.nombre}</SText>
        </SView>
    }
    renderItemListChild(item) {
        const isSelect = this.state.select == item.key;
        return <SView col={"xs-12"} row center
            style={{
                padding: 4,
                backgroundColor: isSelect ? STheme.color.info + "99" : "transparent",
            }}
            onPress={() => {

                this.verLote(item.points)
                this.selectZona(item)
            }}
        >
            <SView width={8} />
            <SView style={{
                width: 16, height: 16,
                // borderWidth: 0.5,
                // borderColor: STheme.color.text,
                backgroundColor: item.color ?? STheme.color.card, borderRadius: 2
            }}></SView>
            <SView width={4} />
            <SText flex fontSize={12} bold color={STheme.color.text} numberOfLines={1}>{item.nombre}</SText>
        </SView>
    }

    addNuevaZona(key_zona_poligonal) {
        const points = Actions.create_triangle_by_center(this.mapregion, Math.max(this.mapregion.longitudeDelta, this.mapregion.latitudeDelta) * 0.2)
        console.log(this.mapregion, points)
        const zona = {
            key: "nuevo",
            points: points,
            nombre: "Nueva zona " + (key_zona_poligonal ? "delivery" : "operacion"),
            type: key_zona_poligonal ? "delivery" : "operacion",
            key_zona_poligonal: key_zona_poligonal,
            color: STheme.color.warning
        }
        this.state.lotes.push(zona)
        this.setState({ ...this.state })
        new SThread(200, "hilo", true).start(() => {
            this.selectZona(zona)
        })
    }
    renderListHeader() {
        return <SView col={"xs-12"} row center style={{
            padding: 4,
            paddingBottom: 16,
            backgroundColor: "transparent",
        }}
            onPress={() => {
                this.addNuevaZona();
            }}>
            <SView style={{
                width: 16, height: 16,
            }}>
                <SIcon name='Add' />
            </SView>
            <SView width={4} />
            <SText fontSize={12} bold color={STheme.color.gray}>{"Crear zona"}</SText>
            <SView flex />
        </SView>
    }
    renderListaZonas() {
        console.log(this.state.lotes);
        const arr = Object.values(this.state.lotes.reduce((acumulador, elemento) => {
            if (!elemento.key_zona_poligonal) {
                // Si no tiene key_zona_poligonal, es un "padre"
                const dta = acumulador[elemento.key];
                acumulador[elemento.key] = { ...elemento, data: dta?.data ?? [] };
            } else {
                // Si tiene key_zona_poligonal, lo agregamos a su padre correspondiente
                if (!acumulador[elemento.key_zona_poligonal]) {
                    acumulador[elemento.key_zona_poligonal] = { data: [] };
                }
                acumulador[elemento.key_zona_poligonal].data.push(elemento);
            }
            return acumulador;
        }, {})).map(c => {
            c.data.sort((a, b) => a.nombre < b.nombre ? -1 : 1)
            return c;
        }).sort((a, b) => {
            if (a.nombre < b.nombre) { return -1; }
            if (a.nombre > b.nombre) { return 1; }
            return 0;
        });

        return <SView style={{
            position: "absolute",
            width: 140,
            height: "100%",
            top: 0,
            left: 0,
            padding: 4,
        }} >
            <SView style={{
                backgroundColor: STheme.color.background + "CC",
                // borderWidth: 1,
                height: "100%",
                // borderColor: STheme.color.gray,
                borderRadius: 4,
                maxHeight: 500,
                padding: 4,
            }}>
                <SText center bold color={STheme.color.lightGray}>{"ZONAS (" + this.state.lotes.length + ")"}</SText>
                <SHr h={4} />
                <SHr h={1} color={STheme.color.card} />
                <SHr h={4} />

                <SView row col={"xs-12"} center >
                    <SView flex >
                        <SText color={STheme.color.lightGray} fontSize={10}>{"Restaurantes"}</SText>
                    </SView>
                    <SView width={20} height={20} >
                        <SInput type='checkBox' defaultValue={this.state.verRestaurantes} onChangeText={e => {
                            this.setState({ verRestaurantes: e })
                        }} />
                    </SView>
                </SView>
                <SHr h={4} />
                <SView row col={"xs-12"} center >
                    <SView flex >
                        <SText color={STheme.color.lightGray} fontSize={10}>{"Test"}</SText>
                    </SView>
                    <SView width={20} height={20} >
                        <SInput type='checkBox' defaultValue={this.state.isTest} onChangeText={e => {
                            this.setState({ isTest: e })
                        }} />
                    </SView>

                </SView>
                <SHr h={8} />
                <SView backgroundColor={STheme.color.danger} center onPress={() => {
                    const notificationKey = SUuid()
                    SNotification.send({
                        key: notificationKey,
                        title: "Sincronizando Zona, (esta acción puede tardar varios minutos)",
                        type: "loading",
                    })

                    Actions.sincronizar().then(e => {
                        SNotification.remove(notificationKey);
                        SNotification.send({
                            key: notificationKey,
                            title: "zona sincronizada con éxito",
                            body: "",
                            color: STheme.color.success,
                            time: 5000,
                        })
                    }).catch(e => {
                        SNotification.remove(notificationKey);
                        SNotification.send({
                            key: notificationKey,
                            title: "Error al sincronizar zona",
                            body: e.error,
                            color: STheme.color.danger,
                            time: 5000,
                        })
                    })

                    this.setState({ select: null })
                }}>
                    <SText padding={4} fontSize={10}>Sincronizar Zonas</SText>
                </SView>

                {!this.state.isTest ? null : <>
                    <SView>
                        {Object.keys(this.testPoints).map((k) => <SView row center>
                            <SView width={8} />
                            <SView style={{
                                width: 12, height: 12, backgroundColor: this.testPoints[k].color,
                                borderRadius: 100, borderWidth: 0.5, borderColor: STheme.color.text

                            }}></SView>
                            <SView width={4} />
                            <SText fontSize={10} color={STheme.color.lightGray}>{k}</SText>
                            <SView flex />
                        </SView>)}
                    </SView></>}
                <SHr h={4} />
                <SHr h={1} color={STheme.color.card} />
                <SHr h={4} />
                <SectionList
                    sections={arr}
                    ListHeaderComponent={() => this.renderListHeader()}
                    renderSectionHeader={({ section }) => {
                        return this.renderItemList(section)
                    }}
                    renderItem={({ item }) => {
                        return this.renderItemListChild(item)
                    }}
                />
                {/* <FlatList data={this.state.lotes.sort((a, b) => {

                    if (a.nombre < b.nombre) { return -1; }
                    if (a.nombre > b.nombre) { return 1; }
                    return 0;
                })}
                    ItemSeparatorComponent={() => <>
                        <SHr h={4} />
                        <SHr h={1} color={STheme.color.card} />
                        <SHr h={4} />
                    </>}
                    ListHeaderComponent={() => this.renderListHeader()}
                    renderItem={({ item }) => {
                        return this.renderItemList(item)
                    }} /> */}
            </SView>
        </SView>
    }

    testPoints = {
        cliente: { lat: -17.783799, lng: -63.180, color: "#f00" },
        restaurante: { lat: -17.793799, lng: -63.180, color: "#0f0" },
    }

    handleTest() {
        SSocket.sendPromise({
            component: "zona_poligonal",
            type: "calcularZonas",
            ...this.testPoints
        }).then(e => {
            if (e?.data?.isDelivery) {
                SNotification.send({
                    title: "Se permite delivery",
                    color: STheme.color.success,
                    time: 5000
                })
            } else if (e?.data?.isRecoger) {
                SNotification.send({
                    title: "Se permite recoger del lugar",
                    color: STheme.color.warning,
                    time: 5000
                })
            } else {
                SNotification.send({
                    title: "No se permite la compra",
                    color: STheme.color.danger,
                    time: 5000
                })
            }
            console.log(e);
        })
    }
    renderTestMarkersButtom() {
        if (!this.state.isTest) return null;
        return <SView style={{ position: "absolute", bottom: 0, padding: 8, backgroundColor: STheme.color.text }} onPress={() => {
            console.log("asdasd")
            this.handleTest();
        }} >
            <SText bold color={STheme.color.background}>{"ENVIAR TEST"}</SText>
        </SView>
    }

    renderTestMarkers = () => {
        if (!this.state.isTest) return null;
        return Object.keys(this.testPoints).map((key, index) => {
            const point = this.testPoints[key];
            return <SMapView.SMarkerDragable key={key} latitude={point.lat} longitude={point.lng}
                icon={{
                    path: "CIRCLE",
                    fillColor: point.color,
                    scale: 7,
                    fillOpacity: 0.8,
                    strokeColor: STheme.color.text,
                    strokeWeight: 1
                }}

                onDragEnd={e => {
                    this.testPoints[key].lat = e.latitude;
                    this.testPoints[key].lng = e.longitude;
                    this.handleTest();
                    // this.setState({...this.state})
                }} />
        })
    }
    render() {
        return (
            <SPage title={"Zonas"} disableScroll>
                <SView col={"xs-12"} flex center>
                    <SMapView ref={ref => this.map = ref} initialRegion={{
                        latitude: -17.783799,
                        longitude: -63.180,
                        latitudeDelta: 0.9,
                        longitudeDelta: 0.9
                    }}
                        onRegionChangeComplete={e => {
                            this.mapregion = e;
                        }}
                        // customMapStyle={customMapStyle}
                        onPress={this.pressLote.bind(this)}
                    >
                        {this.polilineRepaint()}
                        {this.markerRepaint()}
                        {this.markerCenterRepaint()}
                        {this.renderRestaurantesMarker()}

                        {this.renderTestMarkers()}

                    </SMapView>
                    {this.renderTestMarkersButtom()}
                    {/* <SView card height={150}>
                    <SButtom type='danger' style={{ height: 20 }} onPress={() => {
                        this.crearLote()
                    }}>Crear Lote</SButtom>
                    {this.lotesRepaint()}

                </SView> */}
                    {/* {this.lotesRepaint()} */}
                    {this.renderListaZonas()}
                    {this.renderSelectComponent()}
                </SView>
            </SPage >
        );
    }
}


//make this component available to the app
export default index;



