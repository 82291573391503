import { SModel } from "servisofts-model";
import Action from "./Action";
import Reducer from "./Reducer";

export default new SModel<Action, Reducer>({
    info: {
        component: "descuento_zona_poligonal"
    },
    Columns: {
        "key": { type: "text", pk: true },
        "key_usuario": { type: "text", fk: "usuario" },
        "fecha_on": { type: "timestamp", label: "Fecha de creación" },
        "estado": { type: "integer" },
        "key_zona_poligonal": { type: "timestamp", fk: "zona_poligonal", label: "Key Zona Poligonal" },
        "key_descuento": { type: "timestamp", fk: "descuento", label: "Key Descuento" },
    },
    Action,
    Reducer,
});