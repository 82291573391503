import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup } from 'servisofts-component';
import CryptoJS from 'crypto-js';
import Model from '../../Model';
import DatosDocumentosEditar from './Components/DatosDocumentosEditar';

class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["Password"]
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }
    $inputs() {
        var inputs = super.$inputs();
        // inputs["Password"].type = "password"
        // inputs["Password"].values = CryptoJS.MD5(inputs["Password"].values).toString();
        inputs["Correo"].type = "email"
        inputs["Telefono"].type = "phone"
        
        inputs["CI_EXPE"].type = "select"
        inputs["CI_EXPE"].defaultValue = inputs["CI_EXPE"].defaultValue
        inputs["CI_EXPE"].options = [
            { key: '', content: '' },
            { key: 'SCZ', content: 'Santa Cruz' },
            { key: 'LP', content: 'La Paz' },
            { key: 'CBBA', content: 'Cochabamba' },
            { key: 'OR', content: 'Oruro' },
            { key: 'PT', content: 'Potosí' },
            { key: 'CH', content: 'Chuquisaca' },
            { key: 'TJA', content: 'Tarija' },
            { key: 'PD', content: 'Pando' },
            { key: 'EX', content: 'Extranjero' },
        ]

        inputs["enable"].type = "select"
        inputs["enable"].defaultValue = inputs["enable"].defaultValue == "true" ? "true" : "false"
        inputs["enable"].options = [{ key: "", content: "SELECCIONAR" }, { key: "true", content: "SI" }, { key: "false", content: "NO" }]
        inputs["tester"].type = "select"
        inputs["tester"].options = [{ key: "", content: "NO" }, { key: "group1", content: "Grupo 1" }, { key: "group2", content: "Grupo 2" }]
        return inputs;
    }
    $onSubmit(data) {
        delete data["Password"];
        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            key_usuario: Model.usuario.Action.getKey()
        }).then((resp) => {
            // SNavigation.goBack();
            this.presolve(this.pk)
        }).catch(e => {
            console.error(e);
        })
    }

    $submitName() {
        return ""
    }
    $footer() {
        return <DatosDocumentosEditar key_usuario={this.pk} onSubmit={() => {
            return new Promise((resolve, reject) => {
                this.presolve = resolve;
                this.form.submit();
                // resolve("KEY_USUARIO");
            })
        }} />
    }
}

export default connect(index);