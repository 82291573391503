import { Text, TextStyle, TouchableOpacityProps, View } from 'react-native'
import React, { Component } from 'react'
import { SPopup, SText, SView, SViewProps } from 'servisofts-component'


type BtnPropsType = {
    hidden: Boolean,
    children?: any,
    color?: any,
    textStyle?: TextStyle

} & SViewProps
export default class Btn extends Component<BtnPropsType> {
    state = {}
    render() {
        // if (this.state.hidden) return null;
        let item = this.props.children;
        if (typeof item == "string") {
            item = <SText fontSize={16} bold color={this.props.color} style={this.props.textStyle}>{this.props.children}</SText>;
        }
        return (<SView col={"xs-12"} height={50} card center   {...this.props} >
            {item}
        </SView>
        )
    }
}