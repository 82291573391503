import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SButtom, SNavigation, SPopup, SText } from 'servisofts-component';
import Model from '../../Model';
import SSocket from 'servisofts-socket'
import PButtom from '../../Components/PButtom';

class index extends DPA.new {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["key", "fecha_on", "key_usuario", "estado"]
        });

        this.state = {
            ubicacion: "",
            loading: false
        };
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" })
    }

    $inputs() {
        var imp = super.$inputs();
        imp["telefono"].type = "phone"
        imp["capacidad_envio"].type = "number"
        imp["porcentaje_comision_online"].required = true
        imp["porcentaje_comision_online"].type = "money"
        imp["porcentaje_comision_online"].icon = <SText>{"%"}</SText>
        imp["porcentaje_comision_efectivo"].required = true
        imp["porcentaje_comision_efectivo"].type = "money"
        imp["porcentaje_comision_efectivo"].icon = <SText>{"%"}</SText>
        imp["delivery"].type = "select"
        imp["delivery"].defaultValue = imp["delivery"].defaultValue == true ? "SI" : "NO"
        imp["delivery"].options = [{ key: "", content: "SELECCIONAR" }, { key: "true", content: "SI" }, { key: "false", content: "NO" }]
        imp["recoger"].type = "select"
        imp["recoger"].defaultValue = imp["delivery"].defaultValue == true ? "SI" : "NO"
        imp["recoger"].options = [{ key: "", content: "SELECCIONAR" }, { key: "true", content: "SI" }, { key: "false", content: "NO" }]
        imp["latitude"].onPress = (evt) => {
            // console.log("evt")
            SNavigation.navigate("/restaurante/mapa", {
                callback: (resp) => {
                    this.setState({ ubicacion: resp })
                }
            });
        }
        if (this.state?.ubicacion?.latitude) imp["latitude"].value = this.state?.ubicacion?.direccion;

        imp["foto_portada"] = {
            label: "Portada",
            key: "foto_portada",
            type: "image",
            // defaultValue: SSocket.api.root + "restaurante/portada/" + this.pk,
            col: "xs-12",
            style: {
                height: 400,
            }
        }
        return imp;
    }

    $submitName() {
        return null
    }

    $onSubmit(data) {
        this.setState({ loading: true });

        if (this.state?.ubicacion) {
            data.latitude = this.state?.ubicacion?.latitude;
            data.longitude = this.state?.ubicacion?.longitude;
        }

        if (!data.delivery) {
            data.delivery = false;
        }

        Parent.model.Action.registro({
            data: data,
            key_usuario: Model.usuario.Action.getKey(),
        }).then((resp) => {

            this.form.uploadFiles(
                SSocket.api.root + "upload/restaurante/" + resp.data.key,
                "image_profile"
            );

            this.form.uploadFiles(
                SSocket.api.root + "upload/restaurante/portada/" + resp.data.key,
                "foto_portada"
            );

            this.setState({ loading: false });
            SNavigation.goBack();
        }).catch(e => {
            SPopup.alert("Error en el server: " + e.error)
            console.error(e);
            this.setState({ loading: false });
        })
    }

    $footer() {
        return <PButtom
            danger={true}
            loading={this.state.loading}
            fontSize={20}
            onPress={() => { this.form.submit() }}
        >
            Crear Restaurante
        </PButtom>
    }
}

export default connect(index);