import DPA, { connect } from 'servisofts-page';
import { SDate, SPopup, SHr, SIcon, SImage, SText, STheme, SView, SButtom, SNavigation } from 'servisofts-component';
import { Parent } from "."
import Model from '../../Model';
import item from './item';
class index extends DPA.list {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Lista Descuento",
            excludes: ["key", "fecha_on", "key_usuario", "estado"],
            item: item,
        });
        this.state = {
            select: {
                "pasado": false,
                "futuro": false,
                "activo": true,
                "solo_padres": true,
            },
        }
    }


    $allowNew() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "new" });
    }

    onNew() {
        SPopup.openContainer({
            render: (props) => {
                return <SView >
                    <SHr h={15}/>
                    <SView center>
                        <SView
                            width={200}
                            border={STheme.color.white}
                            padding={10}
                            margin={15}
                            center
                            onPress={() => {
                                SNavigation.navigate(Parent.path + "/new", { type: "delivery" })
                                props.close();
                            }}
                        >
                            <SText>Descuento Delivery</SText>
                        </SView>

                        <SView
                            width={200}
                            border={STheme.color.white}
                            padding={10}
                            margin={15}
                            center
                            onPress={() => {
                                SNavigation.navigate(Parent.path + "/new", { type: "producto" })
                                props.close();
                            }}
                        >
                            <SText>Descuento Producto</SText>
                        </SView>

                        <SView
                            width={200}
                            border={STheme.color.white}
                            padding={10}
                            margin={15}
                            center
                            onPress={() => {
                                SNavigation.navigate(Parent.path + "/new", { type: "tapeke" })
                                props.close();
                            }}
                        >
                            <SText>Descuento Tapeke</SText>
                        </SView>


                        <SView col={"xs-4"} center>
                            <SButtom
                                props={{
                                    type: "danger"
                                }}
                                style={{
                                    width: 200,
                                    height: 40
                                }} onPress={() => {
                                    props.close();
                                }}>
                                {"Cancelar"}
                            </SButtom>
                        </SView>
                    </SView>
                    <SHr h={20} />
                    <SText fontSize={10} padding={10} center>Tenga en cuenta que los descuentos son acumulativos y se descontara hasta llegar a un descuento de 0, tomando en cuenta eso ¿esta seguro que desea crear un descuento?</SText>
                </SView>
            }
        })
    }

    $allowTable() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" });
    }
    $filter(data) {
        return data.estado != 0
    }


    $filter(data) {
        if (data.estado <= 0) return false;
        if (this.state.select.solo_padres && !!data.key_descuento) return false;
        let state = Model.descuento.Action.calcular_estate(data);

        if (!this.state.select.activo && state == "activo") return false;
        if (!this.state.select.pasado && state == "pasado") return false;
        if (!this.state.select.futuro && state == "futuro") return false;
        return true;
    }

    $getData() {
        return Parent.model.Action.getAll();
    }

    $order() {
        return [{ key: "fecha_fin", order: "desc", type: "date" }];
    }

    optionItem({ key, label, color }) {
        var select = !!this.state.select[key]
        return <SView height={35} center style={{
            paddingLeft: 8,
            paddingRight: 8,
            opacity: select ? 1 : 0.5,
            backgroundColor: color + "AA"
        }} onPress={() => {
            if (!select) {
                this.state.select[key] = true;
            } else {
                delete this.state.select[key];
            }
            this.setState({ ...this.state })
        }} row>
            {!select ? null : <> <SIcon name={"Close"} width={8} height={8} fill={STheme.color.text} /> <SView width={8} /></>}
            <SText>{label}</SText>
        </SView>
    }

    $menu() {
        let menu = super.$menu();
        menu.push({ children: this.optionItem({ key: "activo", label: "Activos", color: STheme.color.success }) })
        menu.push({ children: this.optionItem({ key: "futuro", label: "Futuros", color: STheme.color.warning }) })
        menu.push({ children: this.optionItem({ key: "pasado", label: "Vencidos", color: STheme.color.danger }) })
        menu.push({ children: this.optionItem({ key: "solo_padres", label: "Solo Padres", color: STheme.color.lightGray }) })
        return menu;
    }
    // $item(obj) {
    //     let state = Model.descuento.Action.calcular_state_label(obj);
    //     let color = Model.descuento.Action.calcular_state_color(obj);

    //     return <SView key={obj.key} col={"xs-12"} card padding={8} onPress={() => {
    //         this.$onSelect(obj)
    //     }}>
    //         <SView col={"xs-12"} row center>

    //             <SView style={{
    //                 borderRadius: 8,
    //                 backgroundColor: STheme.color.card,
    //             }} center >
    //                 <SText fontSize={16} center style={{
    //                     padding: 4,
    //                     color: color
    //                 }}>{state}</SText>
    //             </SView>

    //             <SView width={25} height={25} backgroundColor={color} style={
    //                 {
    //                     borderRadius: 100,
    //                     marginLeft: 20
    //                 }
    //             } />

    //         </SView>
    //         <SHr />
    //         <SHr height={1} color={STheme.color.text} />
    //         <SHr />
    //         <SView col={"xs-12"} row>
    //             <SView width={4} />
    //             <SView flex row>
    //                 <SView flex>
    //                     <SText bold center>{obj.producto || obj.producto > 0 ? "Descuento Producto" : "Descuento Delivery"}</SText>
    //                     <SHr />
    //                     <SText bold>Descripción del descuento:</SText>
    //                     <SText>{obj?.descripcion ?? "No se pillo descripción"}</SText>

    //                     <SHr h={10} />
    //                     <SText fontSize={16}>{`Fecha Creación:  ${new SDate(obj?.fecha_on, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd")}`}</SText>

    //                     <SHr h={10} />
    //                     <SText fontSize={16} color={color}>{`Fecha Inicio:  ${new SDate(obj?.fecha_inicio, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd")}`}</SText>

    //                     <SHr h={10} />
    //                     <SText fontSize={16} color={color}>{`Fecha Fin:  ${new SDate(obj?.fecha_fin, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd")}`}</SText>

    //                     <SHr h={10} />
    //                     <SText fontSize={16}>{`Porcentaje:  ${(obj?.porcentaje * 100) + " %" ?? "No se pillo porcentaje"}`}</SText>

    //                     <SHr h={10} />
    //                     <SText fontSize={16}>{`Monto:  ${(obj?.monto) + " Bs." ?? "No se pillo monto"}`}</SText>
    //                 </SView>
    //             </SView>
    //         </SView>
    //     </SView>
    // }
}
export default connect(index);