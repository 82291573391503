import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Linking } from 'react-native';
import { SDate, SHr, SImage, SLoad, SMath, SNavigation, SNotification, SPage, SPopup, SText, STheme, SView, SIcon, SForm, SList, SInput, SButtom } from 'servisofts-component';
import Container from '../../Components/Container';
import Model from '../../Model';
import MapaDelPedido from './Components/MapaDelPedido';
import PopupCancelacion from './Components/PopupCancelacion';
import SSocket from 'servisofts-socket';
import BtnNavegar from '../../Components/BtnNavegar';
import PButtom from '../../Components/PButtom';
import descuento from '../descuento';
class index extends Component {

    constructor(props) {
        super(props);
        this.pk = SNavigation.getParam("pk");

        this.totalDescuento = 0;
        this.dontLoadUser = "No cargaron los usuarios, recargue la pagina.";

        this.state = {
            loadingAbonarBillMonPedido: false,
        }
    }

    componentDidMount() {
        this.loadData();
        Model.billetera.Action.CLEAR();
    }

    loadData() {
        this.data = Model.pedido.Action.getDetalle(this.pk);
        this.usuarios = Model.usuario.Action.getAll();

        this.totales = this.calcularTotales();

        if (!this.data || !this.totales) return false;

        return true;
    }

    calcularTotales() {
        if (!this.data) return;
        let totales = {
            totalTapeke: 0,
            totalProducto: 0,
            totalSubProducto: 0,
            total: 0,

            totalDescuentoIteam: 0,
            totalDesuentoTapeke: 0,
        }

        totales.totalTapeke = (this.data?.cantidad * this.data?.precio);

        if (!!this.data?.pedido_producto) {
            Object.values(this.data?.pedido_producto).map((prod) => {
                if (prod.precio_sin_descuento) {
                    totales.totalProducto += (prod.cantidad * prod.precio_sin_descuento)
                    totales.totalDescuentoIteam += (prod.cantidad * prod.precio_sin_descuento) - (prod.cantidad * prod.precio)
                } else {
                    totales.totalProducto += (prod.cantidad * prod.precio)
                }
                if (prod.sub_productos) {
                    Object.values(prod.sub_productos).map((sub) => {
                        if (sub.sub_producto_detalle) {
                            Object.values(sub.sub_producto_detalle).map((subDet) => {
                                totales.totalSubProducto += (subDet?.precio * subDet?.cantidad) * prod?.cantidad;
                            });
                        }
                    });
                }
            });
        }

        if (this.data.descuentos?.length > 0) {
            Object.values(this.data.descuentos).map(des => {
                if (des.total_descuento_tapeke > 0) {
                    totales.totalDesuentoTapeke += des.total_descuento_tapeke;
                }
            })
        }

        totales.total = ((totales.totalTapeke + totales.totalProducto + totales.totalSubProducto));

        return totales;
    }


    label({ label, value, onPress, color }) {
        return <SView col={"xs-12"}>
            <SView col={"xs-12"} row onPress={onPress} style>
                <SText col={"xs-6"} color={color ? color : STheme.color.lightGray} >{label}:</SText>
                <SText col={"xs-6"} style={{
                    alignItems: 'flex-end',
                    textAlign: 'right',
                    color: color ? color : STheme.color.text,
                }}>{value}</SText>
            </SView>
            <SHr h={1} color={STheme.color.lightBlack} />
        </SView>
    }

    changeState() {
        let permisoChangeStatePedidoAdmin = Model.usuarioPage.Action.getPermiso({ url: "/pedido", permiso: "changeStatesAdmin" }, true);
        let permisoChangeStatePedido = Model.usuarioPage.Action.getPermiso({ url: "/pedido", permiso: "changeStates" }, true);

        let estados = [];
        let changeStatePermisión = "";

        if (permisoChangeStatePedidoAdmin?.estado > 0) {
            estados = [
                "",
                "entregado",
                "cancelado",
                "pendiente_pago",
                "pago_en_proceso",
                "pagado",
                "listo",
                "buscando_conductor",
                "confirmando_conductor",
                "esperando_conductor",
                "entregado_conductor",
                "conductor_llego",
                "no_recogido",
                "anulado",
                "timeout_pago"
            ];

            changeStatePermisión = "changeStatesAdmin";
        } else if (permisoChangeStatePedido?.estado > 0) {
            estados = [
                "",
                // "entregado",
                // "pendiente_pago",
                // "pago_en_proceso",
                // "pagado",
                // "listo",
                // "buscando_conductor",
                // "confirmando_conductor",
                // "esperando_conductor",
                "entregado_conductor",
                // "conductor_llego",
                // "no_recogido",
                "cancelado",
                // "anulado",
                // "timeout_pago"
            ];

            changeStatePermisión = "changeStates";
        } else {
            return null;
        }

        return <>
            <SView
                style={{
                    padding: 8,
                    width: "100%",
                }}
                card
                center
            >
                <SHr h={20} />

                <SView style={{
                    padding: 8,
                }}
                    card
                >
                    <SText>{this.data?.state}</  SText>
                </SView>

                <SView col={"xs-12"} style={{
                    padding: 8,
                }} >
                    <SForm
                        inputs={{
                            state: { type: "select", options: estados, label: `Estado |  permiso - ${changeStatePermisión}` }
                        }}
                        onSubmitName={"Cambiar Estado"}
                        onSubmit={(data) => {

                            if (data.state == this.data?.state) {
                                SNotification.send({
                                    title: "Error en la acción",
                                    body: "No se puede cambiar el estado al mismo estado en el que esta el pedido.",
                                    color: STheme.color.danger,
                                    time: 6000,
                                })
                                return
                            } // TODO revisar esta validación

                            switch (data.state) {
                                case "cancelado":
                                    SPopup.openContainer({
                                        key: "PopupCancelacion",
                                        render: (props) => {
                                            return <PopupCancelacion pedido={this.data} />
                                        }
                                    })
                                    break;

                                default:
                                    SSocket.sendPromise2({
                                        data: {
                                            key_usuario: Model.usuario.Action.getKey(),
                                        },
                                        component: "pedido",
                                        type: "change_state",
                                        key_pedido: this.data.key,
                                        state: data.state
                                    }).then(e => {
                                        SNotification.send({
                                            title: "Estado Actualizado",
                                            body: "El pedido se actualizo correctamente",
                                            color: STheme.color.success,
                                            time: 6000,
                                        })
                                        Model.pedido.Action.CLEAR();
                                    }).catch(e => {
                                        SNotification.send({
                                            title: "Error en la acción",
                                            body: e.error,
                                            color: STheme.color.danger,
                                            time: 6000,
                                        })
                                        console.error(e);
                                    })
                                    break;
                            }
                        }}
                    />
                </SView>

                <SView
                    style={{
                        borderTop: 1,
                        borderColor: STheme.color.text
                    }}
                >
                    <SView padding={16} card border={STheme.color.gray}
                        onPress={() => {
                            SSocket.sendPromise({
                                component: "pedido",
                                type: "abonoDriver",
                                key_pedido: this.pk,
                                key_usuario: Model.usuario.Action.getKey()
                            }).then(res => {
                                this.data.montos_extras = res.data
                                SNotification.send({
                                    title: "Estado Actualizado",
                                    body: "El pedido se actualizo correctamente",
                                    color: STheme.color.success,
                                    time: 6000,
                                })
                            }).catch(err => {
                                console.log(err.data);
                            })
                        }}>
                        <SText >Abonar a Driver por cancelación (monto configurado)</SText>
                    </SView>
                    <SHr />

                    {
                        this.data.montos_extras?.abono_driver ?
                            <SView card padding={10}>
                                <SText>Monto Abonado: {this.data.montos_extras?.abono_driver?.monto} Bs.</SText>
                                <SView row center
                                    onPress={() => {
                                        SPopup.confirm({
                                            title: "Eliminar Abono de Driver",
                                            message: "¿Seguro que desea remover abono?, la acción sera registrada",
                                            onPress: () => {
                                                SSocket.sendPromise({
                                                    component: "pedido",
                                                    type: "eliminarAbonoDriver",
                                                    key_pedido: this.pk,
                                                    key_usuario: Model.usuario.Action.getKey()
                                                }).then(res => {
                                                    SNotification.send({
                                                        title: "El pedido se elimino",
                                                        body: "El pedido se elimino correctamente",
                                                        color: STheme.color.success,
                                                        time: 6000,
                                                    })
                                                    Model.pedido.Action.CLEAR();
                                                }).catch(err => {
                                                    console.log(err.data);
                                                })
                                            }
                                        })
                                    }}
                                >
                                    {
                                        this.usuarios ?
                                            <SText flex>{`Usuario abonador: ${this.usuarios[this.data.montos_extras?.abono_driver?.key_usuario]?.Nombres} ${this.usuarios[this.data.montos_extras?.abono_driver?.key_usuario]?.Apellidos}`}</SText>
                                            : <SLoad />
                                    }
                                    <SIcon name={'Delete'} height={30} width={30} />
                                </SView>
                            </SView>
                            : null
                    }

                </SView>
            </SView>
        </>
    }

    changeDriver(usuario_conductor) {
        if (!this.data.delivery) return;

        // Cambiar a rol permisoChangeDriver
        let permisoChangeStatePedido = Model.usuarioPage.Action.getPermiso({ url: "/pedido", permiso: " changeDriver" }, true);

        if (!permisoChangeStatePedido) {
            return null;
        }

        let excluideStates = [
            "pagado",
            "listo",

            // "entregado_conductor",
            // "conductor_llego",
            // "entregado",

            "pago_en_proceso",
            "pendiente_pago",

            "timeout_pago",
            // "no_recogido",
            // "cancelado",
            // "anulado",
        ];

        if (excluideStates.includes(this.data.state)) {
            return null;
        }

        return <>
            <SView
                style={{
                    padding: 10,
                    width: "100%",
                }}
                card
                center
            >
                <SHr h={20} />
                <SView padding={16} card border={STheme.color.gray} onPress={() => {
                    SNavigation.navigate("/driver", {
                        onSelect: (driver) => {
                            if (!driver) return;
                            Model.pedido.Action.editar({
                                data: {
                                    ...this.data,
                                    key_conductor: driver.key
                                }
                            })
                        }
                    })
                }}>
                    <SText >CAMBIAR DRIVER</SText>
                </SView>

                {this.label({
                    label: "Conductor", value: usuario_conductor ? usuario_conductor?.Nombres + " " + usuario_conductor?.Apellidos : "Sin conductor",
                    onPress: () => {
                        if (usuario_conductor.key) {
                            SNavigation.navigate("/usuario/profile", { pk: usuario_conductor.key })
                        }
                    }
                })}
            </SView>
        </>
    }

    abonoBilletera() {
        const handlePressAbono = ({ type, amount }) => {
            this.setState({ loadingAbonarBillMonPedido: true })
            let detalle = `Abono genérico por pedido del restaurante ${this.data?.restaurante?.nombre}`;
            let monto = 0;

            switch (type) {
                case "cancelar":
                    monto = this.totalPagado();
                    detalle = `Abono por cancelación de pedido del restaurante ${this.data?.restaurante?.nombre}`
                    break;

                case "compensar":
                    monto = amount;
                    detalle = `Abono por compensación de cancelación de pedido del restaurante ${this.data?.restaurante?.nombre}`
                    break;
            }

            let data = {
                monto: monto,
                key_cliente: this.data?.key_usuario,
                tipo_pago: "reembolso",
                key_pedido: this.data?.key,
                detalle: detalle
            }

            Model.billetera.Action.registro({
                data: data,
                key_usuario: Model.usuario.Action.getKey()
            }).then((resp) => {
                this.setState({ loadingAbonarBillMonPedido: false })
                SPopup.alert("Abono Exitoso");
            }).catch(e => {
                this.setState({ loadingAbonarBillMonPedido: false })
                SPopup.alert("Error al abonar", e.error);
            });
        };

        const buttonAbono = () => {
            let permisoAbonoBilletera = Model.usuarioPage.Action.getPermiso({ url: "/pedido", permiso: "rechargeWallet" }, true);
            if (permisoAbonoBilletera) {
                return <>
                    <SText>{this.tipoDePago()}</SText>
                    <SHr h={15} />
                    <PButtom
                        danger={true}
                        fontSize={14}
                        medium={true}
                        loading={this.state.loadingAbonarBillMonPedido}
                        onPress={() => {
                            if (this.tipoDePago() == "Efectivo") {
                                SPopup.confirm({
                                    title: "¿Seguro que desea abonar el monto del pedido en método de pago efectivo?",
                                    onPress: () => { () => { handlePressAbono({ type: "cancelar" }) } },
                                });
                            } else {
                                SPopup.confirm({
                                    title: "¿Seguro que desea abonar el monto del pedido?",
                                    onPress: () => { handlePressAbono({ type: "cancelar" }) },
                                });
                            }
                        }}
                    >
                        ABONAR BILLETERA MONTO DEL PEDIDO
                    </PButtom>

                    <SHr h={15} />
                    <PButtom
                        danger={true}
                        fontSize={14}
                        medium={true}
                        loading={this.state.loadingAbonarBillMonPedido}
                        onPress={() => {
                            SPopup.openContainer({
                                render: (props) => {
                                    return <SView col={"xs-11"} center >
                                        <SForm inputs={{
                                            monto: { label: "Monto", type: "number", required: true },
                                        }}
                                            onSubmitName={"ACEPTAR"}
                                            onSubmit={(val) => {
                                                props.close();
                                                SPopup.confirm({
                                                    title: "¿Seguro que desea abonar compensacion?",
                                                    onPress: () => { handlePressAbono({ type: "compensar", amount: val.monto }) },
                                                });
                                            }}
                                        />
                                        <SHr h={30} />
                                    </SView>
                                }
                            })
                        }}
                    >
                        ABONAR COMPENSACIÓN
                    </PButtom>
                </>
            }
        }

        const listTransacciones = () => {
            let permisoListTransactions = Model.usuarioPage.Action.getPermiso({ url: "/pedido", permiso: "listTransactions" }, true);
            let transacciones = Model.billetera.Action.getAll({ key_pedido: this.pk });
            if (permisoListTransactions) {
                return <>
                    <SView>
                        <SText>Transacciones en billetera de este Pedido: </SText>
                        <SHr h={5} />
                        <SList
                            data={transacciones}
                            // data={{}}
                            space={0}
                            order={[{ key: "fecha_on", order: "asc" }]}
                            render={(obj) => {
                                return <>
                                    <SView card
                                        style={{
                                            padding: 10,
                                            marginBottom: 10,
                                        }}
                                        onPress={() => {
                                            SNavigation.navigate("/billetera/profile", { pk: obj.key })
                                        }}
                                    >
                                        <SView width={4} />
                                        <SView flex row>
                                            <SView flex>
                                                <SText fontSize={12}>{`Tipo de Pago:  ${(obj?.tipo_pago)}`}</SText>

                                                <SHr h={2} />
                                                <SText fontSize={12}>{`Fecha Creación:  ${new SDate(obj?.fecha_on, "yyyy-MM-ddThh:mm:ss").toString("yyyy-MM-dd")}`}</SText>

                                                {/* <SHr h={10} /> */}
                                                {/* <SText fontSize={16}>{`Cliente:  ${usuarioCliente?.Nombres} ${usuarioCliente?.Apellidos}`}</SText> */}

                                                {/* <SHr h={10} /> */}
                                                {/* <SText fontSize={16}>{usuarioAdmin ? `Creador Transacción:  ${usuarioAdmin?.Nombres} ${usuarioAdmin?.Apellidos}` : "Creador Transacción: Servidor Tapeke"}</SText> */}

                                                <SHr h={2} />
                                                <SText fontSize={12}>Detalle:  {obj.detalle ? obj.detalle : "No hay detalle"}</SText>

                                                <SHr h={2} />
                                                <SText style={{
                                                    color: obj?.monto < 0 ? STheme.color.danger : "green"
                                                }} fontSize={12}>{`Monto:  ${obj?.monto}`}</SText>
                                            </SView>
                                        </SView>
                                    </SView>
                                </>
                            }}
                        />
                    </SView>
                </>
            }
        }

        return <>
            <SView
                style={{
                    padding: 10,
                    width: "100%",
                }}
                card
                center
            >
                {buttonAbono()}
                <SHr h={15} />
                {listTransacciones()}
            </SView>
        </>
    }


    asignarConductor(conductor) {
        let permisoAsignación = Model.usuarioPage.Action.getPermiso({ url: "/asignacion-driver-pedido", permiso: "ver" }, true);
        let excluideStates = [
            "pagado",
            "listo",

            "entregado_conductor",
            "conductor_llego",
            "entregado",

            "pago_en_proceso",
            "pendiente_pago",

            "timeout_pago",
            "no_recogido",
            "cancelado",
            "anulado",
        ];

        if (excluideStates.includes(this.data.state)) {
            return null;
        }

        if (!permisoAsignación) {
            return null;
        }

        return <SView padding={16} card border={STheme.color.gray} onPress={() => {
            SNavigation.navigate("/driver", {
                onSelect: (driver) => {
                    if (!driver) return;
                    SPopup.confirm({
                        title: "Tomar en cuenta que si se asigna o se reasigna un pedido a una moto que tenga un pedido de otro restaurante aparecerá el pedido sin referencia al restaurante, y si el driver tiene otro pedido en estado entregado_conductor o el driver ya comenzó su flujo de entrega traerá conflicto, siempre revisar que el drive no esté en flujo de entrega y Asegúrate de escoger bien al usuario.",
                        message: driver.Correo,
                        onPress: () => {
                            SSocket.sendPromise2({
                                component: "pedido",
                                type: "forzar_esperando_conductor",
                                estado: "cargando",
                                key_usuario: Model.usuario.Action.getKey(),
                                key_pedido: this.data.key,
                                key_conductor: driver.key
                            }).then(e => {
                                console.log(e);
                            }).catch(e => {
                                SNotification.send({
                                    title: "Error en la accion",
                                    body: e.error,
                                    color: STheme.color.danger,
                                    time: 6000,
                                })
                                console.error(e);
                            })
                        }
                    })
                }
            })
        }}>
            <SText >ASIGNAR CONDUCTOR</SText>
        </SView>
    }

    isDriver(usuario_conductor, precio_delivery) {
        if (precio_delivery > 0) {
            return <>
                <SView style={{
                    padding: 8,
                }} card>
                    <SText>Envió con Delivery</SText>
                </SView>
                <SHr h={15} />
                {this.asignarConductor(usuario_conductor)}
                <SHr h={20} />

                <SText style={{ fontSize: 15 }} >Detalle del Driver:</SText>
                {this.label({
                    label: "Conductor", value: usuario_conductor ? usuario_conductor?.Nombres + " " + usuario_conductor?.Apellidos : "Sin conductor",
                    onPress: () => {
                        if (usuario_conductor.key) {
                            SNavigation.navigate("/usuario/profile", { pk: usuario_conductor.key })
                        }
                    }
                })}
                <SHr h={15} />
                {this.label({ label: "Comision Driver", value: "Bs. " + SMath.formatMoney(this.data?.delivery - this.data?.comision_delivery) })}
                <SHr h={15} />
                {this.label({ label: "Comision Tapeke por delivery", value: "Bs. " + SMath.formatMoney(this.data?.comision_delivery) })}
                <SHr h={15} />
                {this.label({ label: "Precio delivery", value: "Bs. " + SMath.formatMoney(precio_delivery) })}
            </>
        } else {
            return <>
                <SHr h={50} />
                <SView style={{
                    padding: 8,
                }} card>
                    <SText>Recoger del Lugar</SText>
                </SView>
            </>
        }
    }

    useCupon(cupon_usuario) {
        let permisoAbonoBilletera = Model.usuarioPage.Action.getPermiso({ url: "/pedido", permiso: "returnUserCoupon" }, true);

        if (cupon_usuario) {
            let cupon = Model.cupon.Action.getByKey(cupon_usuario?.key_cupon);
            return <>
                <SText>Detalle Cupón:</SText>
                {this.label({
                    label: "Cupón", value: cupon?.descripcion,
                    onPress: () => {
                        SNavigation.navigate("/cupon/profile", { pk: cupon?.key_cupon })
                    }
                })}
                <SHr h={15} />
                {this.label({ label: "Monto cupón", value: "Bs. " + SMath.formatMoney(cupon?.monto) })}

                {
                    permisoAbonoBilletera ?
                        <>
                            <SHr h={15} />
                            <SView padding={16} card border={STheme.color.gray}
                                onPress={() => {
                                    SPopup.confirm({
                                        title: "¿Seguro que desea habilitar el cupón de nuevo al usuario?",
                                        onPress: () => {
                                            Model.cupon_usuario.Action.editar({
                                                data: {
                                                    ...cupon_usuario,
                                                    estado: 2
                                                },
                                                key_usuario: Model.usuario.Action.getKey()
                                            }
                                            ).then(res => {
                                                console.log('cupón editado');
                                            }).catch(e => {
                                                console.error('error en cupón');
                                            })
                                        },
                                    });
                                }}
                            >
                                <SText>DEVOLVER CUPÓN AL USUARIO</SText>
                            </SView>
                        </>
                        : null
                }

            </>
        }
    }

    tipoDePago() {
        if (this.data?.tipo_pago && this.data?.tipo_pago?.length > 0) {
            return !!this.data.tipo_pago.find(o => o.type == "efectivo") ? "Efectivo" : `Online - ${this.data.tipo_pago[0].type}`;
        } else {
            return "El pago con QR nunca se pago";
        }
    }

    haveIncentivo() {
        let incentivos = this.data?.incentivos;
        if (incentivos && incentivos?.length > 0) {
            // let incentivosActivos = Model.incentivo.Action.getAllActivos();

            return incentivos.map(incentivo => {
                let userActivateIncentivo;
                if (this.usuarios) userActivateIncentivo = this.usuarios[incentivo?.key_usuario];
                let zona = Model.zona.Action.getByKey(incentivo?.key_zona);

                return <>
                    <SHr h={50} />
                    <SText>Detalle Incentivo {incentivo?.tipo}:</SText>
                    {/* {this.label({ label: "Tipo Incentivo", value: incentivo?.tipo })} */}
                    {this.label({ label: "Descripción Incentivo", value: incentivo?.descripcion })}
                    <SHr h={15} />
                    {this.label({ label: "Monto Incentivo", value: "Bs. " + SMath.formatMoney(incentivo?.monto) })}
                    <SHr h={15} />
                    {this.label({ label: "Monto Incentivo para Tapeke", value: "Bs. " + SMath.formatMoney(incentivo?.monto - incentivo?.monto_driver) })}
                    <SHr h={15} />
                    {this.label({ label: "Monto Incentivo para Driver", value: "Bs. " + SMath.formatMoney(incentivo?.monto_driver) })}
                    <SHr h={15} />
                    {this.label({ label: "Fecha de activación", value: new SDate(incentivo?.fecha_on).toString("yyyy-MM-dd hh:mm") })}
                    {
                        this.usuarios ?
                            <> <SHr h={20} />
                                {this.label({
                                    label: "Activado por", value: userActivateIncentivo?.Nombres + " " + userActivateIncentivo?.Apellidos,
                                    onPress: () => {
                                        if (userActivateIncentivo.key) {
                                            SNavigation.navigate("/usuario/profile", { pk: userActivateIncentivo.key })
                                        }
                                    }
                                })}
                            </>
                            : <SText>{this.dontLoadUser}</SText>
                    }


                    <SHr h={15} />
                    {this.label({ label: "Zona", value: zona?.nombre })}
                </>
            })
        }
    }

    calcularDescuento(descuento) {
        let total = 0;
        let descuentos = Object.values(descuento);

        if (descuentos.length > 0) {
            total = this.calcularTotalDescuento(descuentos[0]);
        }

        if (descuentos.length > 1) {
            const descuentosCombinados = [
                this.encontrarMayorDescuento(descuentos.filter(d => d.delivery)),
                this.encontrarMayorDescuento(descuentos.filter(d => d.producto))
            ].filter(d => d);

            total = descuentosCombinados.reduce((sum, d) => sum + this.calcularTotalDescuento(d), 0);
        }

        return total;
    }

    calcularTotalDescuento(descuento) {
        return descuento.total_descuento_delivery + descuento.total_descuento_producto;
    }

    encontrarMayorDescuento(descuentos) {
        if (descuentos.length === 0) return null;
        return descuentos.reduce((max, actual) => {
            return this.calcularTotalDescuento(actual) > this.calcularTotalDescuento(max) ? actual : max;
        });
    }

    useDescuento(descuento) {
        if (descuento) {
            if (descuento.length < 1) return

            this.totalDescuento = this.calcularDescuento(descuento)
            const descuentosCombinados = [
                this.encontrarMayorDescuento(descuento.filter(d => d.delivery)),
                this.encontrarMayorDescuento(descuento.filter(d => d.producto)),
                this.encontrarMayorDescuento(descuento.filter(d => d.tapeke))
            ].filter(d => d !== null && d !== undefined);

            return <>
                <SText>{this.data?.descuentos?.length > 0 ? `Detalle Descuento:` : ""}</SText>

                {descuentosCombinados.map(descuento => {
                    let userActivateDescuento;
                    if (this.usuarios) userActivateDescuento = this.usuarios[descuento?.key_usuario];
                    return <>
                        {this.label({ label: "Descripción Descuento", value: descuento?.descripcion })}
                        <SHr h={15} />
                        {this.label({ label: "Porcentaje", value: (descuento?.porcentaje * 100) + " %" })}

                        <SHr h={15} />
                        {this.label({ label: "Monto", value: `Bs. ${SMath.formatMoney(descuento?.monto)}` })}

                        <SHr h={15} />
                        {this.label({ label: "Descuento Producto", value: `Bs. ${SMath.formatMoney(descuento?.total_descuento_producto ?? 0)}` })}
                        <SHr h={15} />

                        <SHr h={15} />
                        {this.label({ label: "Descuento Delivery", value: `Bs. ${SMath.formatMoney(descuento?.total_descuento_delivery ?? 0)}` })}
                        <SHr h={15} />

                        <SHr h={15} />
                        {this.label({ label: "Descuento Tapeke", value: `Bs. ${SMath.formatMoney(descuento?.total_descuento_tapeke ?? 0)}` })}
                        <SHr h={15} />

                        <SHr h={15} />
                        {this.label({ label: "Cubre Tapeke", value: descuento?.cobertura * 100 + " %" })}
                        <SHr h={15} />

                        {
                            this.usuarios ?
                                <>
                                    {this.label({
                                        label: "Activado por", value: userActivateDescuento?.Nombres + " " + userActivateDescuento?.Apellidos,
                                        onPress: () => {
                                            if (userActivateDescuento.key) {
                                                SNavigation.navigate("/usuario/profile", { pk: userActivateDescuento.key })
                                            }
                                        }
                                    })}
                                </>
                                : <SText>{this.dontLoadUser}</SText>
                        }
                        <SHr h={60} />
                    </>
                })}
            </>
        }
    }

    detalleCliente(usuario_cliente, factura_usuario) {
        return <>
            <SText style={{ fontSize: 15 }} >Detalle del Cliente:</SText>
            <SHr h={15} />

            {this.label({
                label: "Cliente", value: usuario_cliente?.Nombres + " " + usuario_cliente?.Apellidos,
                onPress: () => {
                    if (usuario_cliente.key) {
                        SNavigation.navigate("/usuario/profile", { pk: usuario_cliente.key })
                    }
                }
            })}
            <SHr h={15} />
            {this.label({ label: "Dirección cliente", value: this.data?.direccion?.direccion })}
            <SHr h={15} />


            {this.label({ label: "Razón Social", value: !factura_usuario?.razon_social ? "usuario no puso razón social" : factura_usuario?.razon_social })}
            {this.label({ label: "NIT", value: !factura_usuario?.nit ? "usuario no puso nit" : factura_usuario?.nit })}

            <SHr h={15} />
            {this.label({ label: "Teléfono", value: factura_usuario?.phone })}
            <SHr h={15} />

            <SView
                color={STheme.color.text}
                border={STheme.color.gray}
                padding={8}
                onPress={() => {
                    let phoneWp = factura_usuario?.phone.replace(/\+| /g, '');
                    Linking.openURL(`https://api.whatsapp.com/send?phone=${phoneWp}`)
                }}>
                <SText>Ir a WhatsApp Cliente</SText>
            </SView>


            <SHr h={15} />
            <SView style={{ justifyContent: 'flex-end' }} row>
                <BtnNavegar
                    latLng={{
                        latitude: this.data?.direccion?.latitude,
                        longitude: this.data?.direccion?.longitude,
                    }}
                >
                    <SView
                        center
                        backgroundColor={STheme.color.primary}
                        width={80}
                        height={24}
                        style={{
                            borderRadius: 4,
                        }}
                        col={'xs-12'}
                    >
                        <SView row center col={'xs-12'}>
                            <SView width={12} center>
                                <SIcon
                                    name={'Marker'}
                                    width={10}
                                    fill={'#fff'}
                                />
                            </SView>
                            <SView width={5} />
                            <SView width={35}>
                                <SText
                                    color={'#fff'}
                                    center
                                    fontSize={10}
                                >
                                    VIAJAR
                                </SText>
                            </SView>
                        </SView>
                    </SView>
                </BtnNavegar>
            </SView>
        </>
    }

    detalleRestaurante() {
        return <>
            <SText style={{ fontSize: 15 }} >Detalle del Restaurante:</SText>
            <SHr h={15} />

            {this.label(
                {
                    label: "Restaurante", value: this.data?.restaurante?.nombre,
                    onPress: () => {
                        if (this.data?.restaurante?.key) {
                            SNavigation.navigate("/restaurante/profile", { pk: this.data?.restaurante?.key })
                        }
                    }
                }
            )}

            <SHr h={15} />
            {this.label({ label: "Dirección restaurante", value: this.data?.restaurante?.direccion })}

            <SHr h={15} />
            <SView style={{ justifyContent: 'flex-end' }} row>
                <BtnNavegar
                    latLng={{
                        latitude: this.data?.restaurante?.latitude,
                        longitude: this.data?.restaurante?.longitude,
                    }}
                >
                    <SView
                        center
                        backgroundColor={STheme.color.primary}
                        width={80}
                        height={24}
                        style={{
                            borderRadius: 4,
                        }}
                        col={'xs-12'}
                    >
                        <SView row center col={'xs-12'}>
                            <SView width={12} center>
                                <SIcon
                                    name={'Marker'}
                                    width={10}
                                    fill={'#fff'}
                                />
                            </SView>
                            <SView width={5} />
                            <SView width={35}>
                                <SText
                                    color={'#fff'}
                                    center
                                    fontSize={10}
                                >
                                    VIAJAR
                                </SText>
                            </SView>
                        </SView>
                    </SView>
                </BtnNavegar>
            </SView>

            <SHr h={20} />
            {this.label({ label: "Nota Cliente", value: this.data?.nota_cliente ? this.data?.nota_cliente : "El cliente no puso ninguna nota." })}
            <SHr h={20} />
            {this.label({ label: "Comisión restaurante", value: "Bs. " + SMath.formatMoney(this.totales.total - this.data?.comision_restaurante) })}
            <SHr h={20} />
            {this.label({ label: "Comisión Tapeke por venta", value: "Bs. " + SMath.formatMoney((this.data?.comision_restaurante)) })}
            <SHr h={20} />
            {this.label({ label: "Total Venta", value: "Bs. " + SMath.formatMoney((this.totales.total)) })}
        </>
    }

    detalleTapeke() {
        if (this.data?.cantidad < 1) return

        return <>
            <SView card padding={10}>
                <SText style={{ fontSize: 20 }} >Detalle Tapeke:</SText>
                <SHr h={15} />
                {this.label({ label: "Cantidad", value: "x " + this.data?.cantidad })}

                <SHr h={15} />
                {this.label({ label: "Precio unitario", value: "Bs. " + SMath.formatMoney(this.data?.precio) })}

                {
                    this.totales.totalDesuentoTapeke > 0 ?
                        <>
                            <SHr h={15} />
                            {this.label({ label: "Descuento Tapeke", value: "Bs. " + SMath.formatMoney(this.totales.totalDesuentoTapeke), color: STheme.color.danger })}
                        </>
                        : null
                }

                <SHr h={15} />
                {this.label({ label: `Precio Unitario X Cantidad`, value: "Bs. " + SMath.formatMoney(this.totales.totalTapeke - this.totales.totalDesuentoTapeke), color: STheme.color.success })}
            </SView>
        </>
    }


    detalleProducto() {
        if (!!this.data?.pedido_producto) {
            // let tsp = 0;

            const renderSubProd = (pedido_producto) => {
                if (pedido_producto.sub_productos) {
                    return Object.values(pedido_producto.sub_productos).map((sub) => {
                        return <>
                            <SView >
                                <SText>Nombre: {sub?.nombre}</SText>
                                <SText>Descripción: {sub?.descripcion}</SText>
                                {sub.sub_producto_detalle ? renderSubProdDet(sub.sub_producto_detalle) : ""}
                            </SView>
                        </>
                    })
                }
            }

            const renderSubProdDet = (sub_producto_detalle) => {
                return Object.values(sub_producto_detalle).map((subDet) => {
                    return <>
                        <SView card padding={5} margin={5}>
                            <SView row flex>
                                <SView flex>
                                    <SText>Nombre: {subDet?.nombre}</SText>
                                    <SText>Cantidad: {subDet?.cantidad}</SText>
                                    <SText>Precio: {subDet?.precio ? `${SMath.formatMoney(subDet?.precio)} Bs.` : "No tiene precio"}</SText>
                                    <SText>{subDet?.precio ? `Total: ${SMath.formatMoney(subDet?.precio * subDet?.cantidad)} Bs.` : null}</SText>
                                </SView>
                            </SView>
                        </SView>
                    </>
                })
            }

            const TotSubProd = (pedido_producto) => {
                if (pedido_producto.sub_productos) {
                    let tsp = 0;
                    Object.values(pedido_producto.sub_productos).map((sub) => {
                        if (sub.sub_producto_detalle) {
                            Object.values(sub.sub_producto_detalle).map((subDet) => {
                                tsp += (subDet?.precio * subDet?.cantidad);
                            });
                        }
                    })
                    return tsp;
                }
            }

            return <>
                <SView
                    style={{
                        padding: 8,
                        border: 0.5,
                        borderBottomColor: STheme.color.text,
                    }}
                >
                    <SText style={{ fontSize: 20 }}>Detalle Productos:</SText>
                    <SList
                        data={this.data?.pedido_producto}
                        render={(pedido_producto) => {
                            return <>
                                <SView key={pedido_producto.key} card padding={5} margin={5}>
                                    <SView row flex>
                                        <SView flex>
                                            <SText>Descripción: {pedido_producto?.descripcion}</SText>
                                            <SText>Cantidad: {pedido_producto?.cantidad}</SText>
                                            <SText>Precio: {`${SMath.formatMoney(pedido_producto?.precio_sin_descuento)} Bs.`}</SText>
                                            {pedido_producto?.descuento_monto ? <SText color={STheme.color.danger}>Descuento Monto: {`${SMath.formatMoney(pedido_producto?.descuento_monto)} Bs.`}</SText> : null}
                                            {pedido_producto?.descuento_porcentaje ? <SText color={STheme.color.danger}>Descuento Porcentaje: {`${pedido_producto?.descuento_porcentaje * 100} %`}</SText> : null}
                                            <SText>Total Producto: {`${SMath.formatMoney(pedido_producto?.precio * pedido_producto?.cantidad)} Bs.`}</SText>
                                        </SView>
                                        <SView height={80} width={80} style={{ marginRight: 10 }}>
                                            <SImage src={Model.producto._get_image_download_path(SSocket.api, pedido_producto?.key_producto)} />
                                        </SView>
                                    </SView>
                                    <SView>
                                        {pedido_producto?.sub_productos?.length > 0 ?
                                            <>
                                                <SText
                                                    style={{
                                                        fontSize: 15,
                                                        borderBottomWidth: 0.5,
                                                        borderBottomColor: STheme.color.text,
                                                    }}
                                                    center>Detalle Sub Productos</SText>
                                                {renderSubProd(pedido_producto)}
                                                <SHr h={15} />
                                                {this.label({ label: "Total Sub Producto", value: "Bs. " + SMath.formatMoney(TotSubProd(pedido_producto)) })}
                                            </>
                                            : null}
                                    </SView>
                                </SView>
                            </>
                        }}
                    />

                    <SHr h={15} />
                    {this.label({ label: "Total productos", value: "Bs. " + SMath.formatMoney(this.totales.totalProducto) })}
                    {this.label({ label: "Total Descuento Iteam", value: "- Bs. " + SMath.formatMoney(this.totales.totalDescuentoIteam), color: STheme.color.danger })}
                    {this.label({ label: "Total Sub Producto", value: "Bs. " + SMath.formatMoney(this.totales.totalSubProducto) })}
                </SView>

            </>

        }
    }

    detallePedido() {
        let montoCupon = 0;
        if (!!this.data?.cupon_usuario) montoCupon = this.data?.cupon_usuario?.monto;

        return <>
            <SText style={{ fontSize: 15 }} >Detalle del Pedido:</SText>

            <SHr h={15} />
            {this.label({ label: "Fecha del pedido", value: new SDate(this.data?.fecha_on).toString("yyyy-MM-dd hh:mm") })}

            <SHr h={15} />
            {this.label({
                label: "Horario del pedido", value: this.data?.fecha + " " + this.data?.horario?.hora_inicio + " - " + this.data?.horario?.hora_fin,
                onPress: () => {
                    SNavigation.navigate("/restaurante/horario/horariohistorico", { pk: this.data?.horario?.key })
                }
            })}

            <SHr h={15} />
            <SView style={{
                padding: 10,
            }} card col={"xs-12"}>

                {this.label({ label: "Tipo de pago", value: this.tipoDePago() })}
                <SHr h={15} />

                <SView padding={10}>
                    <SHr h={15} />
                    {this.detalleTapeke()}

                    <SHr h={15} />
                    {this.detalleProducto()}

                    <SHr h={15} />
                    {this.label({ label: "Total Tapeke + Prod + Sub Prod - Descuento", value: "Bs. " + SMath.formatMoney((this.totales.total) - (this.totales.totalDescuentoIteam + this.totales.totalDesuentoTapeke)) })}
                </SView>

                {/* {
                    this.data?.descuentos?.length > 0 ?
                        <>
                            <SHr h={40} />
                            {this.label({ label: "Precio del delivery", value: "Bs. " + SMath.formatMoney(this.data?.delivery) })}
                            <SHr h={15} />
                            {this.label({ label: "Total Descuentos", value: "Bs." + SMath.formatMoney(this.totalDescuento), color: STheme.color.danger })}
                        </>
                        : null
                } */}

                <SHr h={40} />
                {this.label({ label: "Precio del delivery", value: "Bs. " + SMath.formatMoney(this.data?.delivery) })}

                <SHr h={15} />
                {/* {this.label({ label: "Tarifa de Servicio", value: "Bs. " + SMath.formatMoney(this.data?.tarifa_servicio?.monto) })} */}

                <SHr h={15} />
                {this.label({ label: `Delivery${this.data?.monto_incentivos ? " + Incentivo" : ""} ${this.data?.descuentos?.length > 0 ? "- Descuento" : ""}`, value: "Bs. " + SMath.formatMoney((this.data?.delivery + (this.data?.monto_incentivos ?? 0) - this.data?.total_descuento_delivery)), color: STheme.color.danger })}


                <SHr h={15} />
                {this.data?.monto_incentivos ? this.label({ label: "Precio Incentivo delivery", value: "Bs. " + SMath.formatMoney(this.data?.monto_incentivos ?? 0) }) : null}

                <SHr h={15} />
                {!!this.data?.cupon_usuario ? this.label({ label: "Cupón", value: "Bs." + SMath.formatMoney(montoCupon), color: STheme.color.danger }) : null}

                {this.label({ label: "Total Descuentos", value: "Bs." + SMath.formatMoney(this.totalDescuento), color: STheme.color.danger })}

                <SHr h={40} />
                {this.label({ label: "Total Pagado", value: "Bs. " + parseFloat(this.data.monto_total).toFixed(2)/* SMath.formatMoney(this.totalPagado()) */, color: STheme.color.success })}

            </SView>
        </>
    }

    totalPagado() {
        let montoCupon = 0;
        if (!!this.data?.cupon_usuario) montoCupon = this.data?.cupon_usuario?.monto;

        let delivery = ((this.data?.delivery ?? 0) + (this.data?.monto_incentivos ?? 0))

        let totalPagado = ((this.totales.total + delivery) - montoCupon) - (this.totalDescuento + this.totales.totalDescuentoIteam);
        return totalPagado;
    }

    renderContent() {
        if (!this.loadData()) return <SLoad />
        this.calcularTotales()
        let usuario_cliente;
        if (this.usuarios) usuario_cliente = this.usuarios[this.data?.key_usuario];
        let usuario_conductor;
        if (this.data?.key_conductor) {
            if (this.usuarios) usuario_conductor = this.usuarios[this.data?.key_conductor];
        }

        if (this.data?.state == 'no_registrado') {
            return <>
                <SView col={"xs-12"} style={{ padding: 8 }} center>
                    <SHr />
                    <SText>Estado: </  SText>
                    <SView style={{
                        padding: 8,
                    }} card>
                        <SText>{this.data?.state}</  SText>
                    </SView>

                    <SHr h={20} />
                    <SText style={{ fontSize: 30, color: STheme.color.text }}>El pedido no existe</SText>
                </SView>
            </>
        } else {
            return <SView col={"xs-12"} style={{ padding: 8 }} center>
                <SHr />
                <SView style={{
                    padding: 8,
                }} card>
                    <SText>{this.data?.state}</  SText>
                </SView>

                {
                    this.usuarios ?
                        <>
                            <SHr h={20} />
                            {this.detalleCliente(usuario_cliente, this.data.factura)}
                        </>
                        : <SText>{this.dontLoadUser}</SText>
                }

                <SHr h={50} />
                {this.detalleRestaurante()}

                <SHr h={20} />
                {this.isDriver(usuario_conductor, this.data?.delivery)}

                <SHr h={20} />
                {this.useDescuento(this.data?.descuentos)}

                <SHr h={20} />
                {this.useCupon(this.data?.cupon_usuario)}

                <SHr h={20} />
                {this.haveIncentivo()}

                <SHr h={20} />
                {this.detallePedido()}

                <SHr h={50} />
                <SText style={{
                    padding: 8
                }} card onPress={() => {
                    SNavigation.navigate("/pedido/movimientos", { pk: this.pk })
                }}>Ver movimientos</SText>
                <SHr height={50} />

                {this.label({ label: "Distancia cliente - partner", value: `${parseFloat(this.data?.distancia ?? 0).toFixed(2)} Metros` })}
                <SHr height={20} />
                <MapaDelPedido pedido={this.data} />

                <SHr height={15} />
                {this.changeDriver(usuario_conductor)}

                <SHr height={15} />
                {this.changeState()}

                <SHr height={15} />
                {this.abonoBilletera()}
                <SHr height={100} />
            </SView>
        }
    }

    render() {
        return (
            <SPage onRefresh={(resolve) => {
                Model.pedido.Action.CLEAR();
                resolve();
            }}>
                <Container>
                    {this.renderContent()}
                </Container>
            </SPage>
        );
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);