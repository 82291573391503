import React, { Component } from 'react';
import { Text, TextStyle, View } from 'react-native';
import { SDate, SImage, SNotification, SPage, SText, STheme, SView, } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import { DinamicTable } from 'servisofts-table'
const DATATEST = [
    { nombre: "ricky", apellido: "garcia" },
    { nombre: "pedro", apellido: "sad" },
    { nombre: "pedro ksad aksd kas", apellido: "sad" },
]

// type DataType = typeof DATATEST[0]
type DataType = any
const Col = DinamicTable.Col<DataType>


const ImageLabel = ({ label, src, textStyle }: any) => {
    return <SView row >
        <SView width={16} height={16} style={{ borderRadius: 100, overflow: "hidden", backgroundColor: STheme.color.card }}>
            <SImage src={src} style={{
                resizeMode: "cover"
            }} />
        </SView>
        <SView width={4} />
        <Text style={[textStyle as TextStyle, { flex: 1 }]} numberOfLines={1} >{label}</Text>
    </SView>
}
export default class t2 extends Component {

    async loadData() {
        SNotification.send({
            key: "loadData",
            title: "Cargando",
            type: "loading"
        })
        const resp: any = await SSocket.sendPromise({
            component: "pedido",
            type: "reporte_pedido_tabla",
        })
        SNotification.remove("loadData")
        return resp.data;
    }

    render() {
        return <SPage title={"Test"} disableScroll>
            <SView col={"xs-12"} flex>
                <DinamicTable loadData={this.loadData}
                    colors={{
                        text: STheme.color.text,
                        border: STheme.color.card,
                        background: STheme.color.background,
                        card: STheme.color.card
                    }}
                    cellStyle={{
                        borderWidth: 0,
                        height: 30,
                        // borderBottomWidth:1,
                    }}
                    textStyle={{
                        fontSize: 12,
                    }}
                >
                    <Col key={"index"} label='#' width={30}
                        data={e => e.index + 1}
                    />
                    <Col key={"restaurante"} label='Restaurante' width={140}
                        data={e => e.row.nombre_restaurante}
                    />
                    <Col key={"hora_inicio"} label='Hora Inicio' width={50}
                        data={e => e.row.hora_inicio}
                    />
                    <Col key={"hora_fin"} label='Hora fin' width={50}
                        data={e => e.row.hora_fin}
                        
                    />
                    <Col key={"state"} label='State' width={50}
                        data={e => e.row.state}
                    />
                    {/*
                    <Col key={"cliente"} label='Cliente' width={100}
                        data={e => e.row.cliente.descripcion}
                        customComponent={e => <ImageLabel label={e.data} src={SSocket.api.root + "cliente/" + e.row?.cliente?.key} textStyle={e.textStyle} />}
                    />
                    <Col key={"evento"} label='Evento' width={100}
                        data={e => e.row.evento.descripcion}
                    />
                    <Col key={"staff"} label='Staff' width={100}
                        data={e => e.row.staff_tipo.descripcion}
                        customComponent={e => <ImageLabel label={e.data} src={SSocket.api.root + "staff_tipo/" + e.row?.staff_tipo?.key} textStyle={e.textStyle} />}
                    />
                    <Col key={"horas"} label='Horas' width={80}
                        dataType='number'
                        cellStyle={{
                            alignItems: "flex-end"
                        }}
                        data={e => {
                            const time = new SDate(e.row.staff.fecha_inicio, "yyyy-MM-ddThh:mm:ssTZD").diffTime(new SDate(e.row.staff.fecha_fin, "yyyy-MM-ddThh:mm:ssTZD"))
                            return time / 1000 / 60 / 60
                        }}
                        format={e => e.data.toFixed(2)}
                    />
                    <Col key={"staff_descripcion"} label='Staff Descripcion' width={200}
                        data={e => e.row.staff.descripcion}
                         />
                    <Col key={"fecha"} label='Fecha' width={150}
                        dataType='date'
                        data={e => new SDate(e.row.evento.fecha, "yyyy-MM-ddThh:mm:ss").date}
                        format={e => new SDate(e.data).toString("MONTH dd, yyyy")}

                    />
                    <Col key={"inicio"} label='Inicio' width={80}
                        dataType='date'
                        data={e => new SDate(e.row.staff.fecha_inicio, "yyyy-MM-ddThh:mm:ssTZD").date}
                        format={e => new SDate(e.data).toString("HH")}
                        textStyle={{ color: STheme.color.success }}
                    />

                    <Col key={"fin"} label='Fin' width={80}
                        dataType='date'
                        data={e => new SDate(e.row.staff.fecha_fin, "yyyy-MM-ddThh:mm:ssTZD").date}
                        format={e => new SDate(e.data).toString("HH")}
                        textStyle={{ color: STheme.color.danger }}
                    />

                    <Col key={"state"} label='State'
                        data={e => {
                            const fecha_inicio = e.row?.staff?.fecha_inicio;
                            const fecha_fin = e.row?.staff?.fecha_fin;
                            if (!fecha_inicio || !fecha_fin) return "ERROR"
                            const fecha_inicio_time = new SDate(fecha_inicio, "yyyy-MM-ddThh:mm:ssTZD").getTime();
                            const fecha_fin_time = new SDate(fecha_fin, "yyyy-MM-ddThh:mm:ssTZD").getTime();
                            if (fecha_inicio_time > fecha_fin_time) {
                                return "ERROR"
                            }
                            if (fecha_inicio_time > new Date().getTime()) {
                                return "PENDING"
                            }
                            if (fecha_fin_time < new Date().getTime()) {
                                return "FINISHED"
                            }
                            return "READY"
                        }}
                        customComponent={e => {
                            let color: any = STheme.color.primary;
                            switch (e.dataFormat) {
                                case "ERROR":
                                    color = STheme.color.danger;
                                    break;
                                case "PENDING":
                                    color = STheme.color.warning;
                                    break;
                                case "FINISHED":
                                    color = STheme.color.success;
                                    break;
                                case "READY":
                                    color = STheme.color.primary;
                                    break;
                            }

                            return <SView col={"xs-12"} center>
                                <SView padding={4} center style={{
                                    backgroundColor: color,
                                    borderRadius: 4,
                                }}>
                                    <Text style={[e.textStyle as TextStyle, { color: "#fff", fontWeight: "bold", fontSize: 10 }]} >{e.dataFormat}</Text>
                                </SView>
                            </SView>
                        }} /> */}

                    {/* <Col key={"testcustom"} label='TestCustom'
                        data={e => e.row.apellido}
                        customComponent={e => <Text style={[e.textStyle as TextStyle, { color: "#f0f" }]} >{e.dataFormat}</Text>} /> */}
                </DinamicTable>
            </SView>
        </SPage>
    }
}
