import DPA, { connect } from 'servisofts-page';
import { Parent } from "."
import { SForm, SHr, SInput, SList, SNativeModules, SNavigation, SText, STheme, SView } from 'servisofts-component';
import Model from '../../Model';
import { EditarUsuarioRol } from 'servisofts-rn-roles_permisos';
import DatosDocumentos from './Components/DatosDocumentos';
import HabilitarDriver from './Components/HabilitarDriver';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent, excludes: ["key", "Password"],
            itemType: "1"
        });
    }

    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }

    $allowAccess() {

        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }
    $allowDelete() {
        if (this.data?.estado == 0) return;
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }
    $allowRestore() {
        if (this.data?.estado != 0) return false;
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "restore" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }

    $footer() {
        return <SView col={"xs-12"} center>
            <SHr h={20} />
            <SView onPress={() => {
                SNavigation.navigate('/usuario/edit_password', { pk: this.pk })
            }} backgroundColor={STheme.color.danger} width={150} padding={8} borderRadius={8} center>
                <SText>
                    Editar Contraseña
                </SText>
            </SView>
            <SHr h={20} />
            <DatosDocumentos key_usuario={this.pk} />
            {/* <SHr height={16} /> */}
            {/* <DatosDocumentosEditar key_usuario={this.pk} /> */}
            <SHr />

            <SHr height={16} />
            <HabilitarDriver key_usuario={this.pk} />
            <SHr h={40} />
            <EditarUsuarioRol key_usuario={this.pk} url={"/usuario"} permiso={"edit_rol"} />
            <SHr height={16} />

        </SView>

    }
}
export default connect(index);