import DPA, { connect } from 'servisofts-page';
import { Parent } from ".."
import Model from '../../../Model';
import { SText, SView, STheme, SDate, SHr, SNavigation, SLoad, SList, SIcon, SPopup } from 'servisofts-component';
import item from '../item';
import DescuentosHijos from './Components/DescuentosHijos';
import SSocket from 'servisofts-socket';

class index extends DPA.profile {
    constructor(props) {
        super(props, {
            Parent: Parent,
            title: "Perfil Descuento",
            excludes: ["key", "key_usuario", "estado", "lat", "lng", "key_empresa"],
            item: item
        });
        this.pk = SNavigation.getParam("pk");
    }

    componentDidMount() {
        Parent.model.Action.CLEAR()
        Model.descuento_zona_poligonal.Action.CLEAR()
        Model.descuento_tag.Action.CLEAR()
        this.getZonaPoligonal();
    }

    getZonaPoligonal() {
        SSocket.sendPromise({
            component: "zona_poligonal",
            type: "getAll"
        }).then(e => {
            this.setState({ zonas_poligonales: e.data })
        }).catch(e => {

        })
    }
    onBack() {
        return true;
    }
    $allowEdit() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }

    $allowDelete() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "delete" })
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "ver" })
    }

    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }


    registrar_restaurante = () => {
        if (!this.descuento_zona_poligonal) return null
        SNavigation.navigate("/zona_poligonal", {
            filter: (obj) => {
                if (this.descuento_zona_poligonal) {
                    let item = Object.values(this.descuento_zona_poligonal).find(a => a.key_zona_poligonal == obj.key && a.estado > 0)
                    if (item) {
                        return false
                    }
                }
                return true;
            },
            onSelect: (obj) => {
                if (!obj) return;
                if (this.descuento_zona_poligonal) {
                    let item = Object.values(this.descuento_zona_poligonal).find(a => a.key_zona_poligonal == obj.key && a.estado > 0)
                    if (item) {
                        SPopup.alert("El restaurante ya se encuentra registrado.")
                        return;
                    }
                }
                Model.descuento_zona_poligonal.Action.registro({
                    data: {
                        key_descuento: this.pk,
                        key_zona_poligonal: obj.key,
                    },
                    key_usuario: Model.usuario.Action.getKey()
                })
            }
        })
    }

    getZonas() {
        this.descuento_zona_poligonal = Model.descuento_zona_poligonal.Action.getAll({ key_descuento: this.pk });
        // var descuento_zona_poligonal = Model.descuento_zona_poligonal.Action.getAll({ key_descuento: this.pk });
        // var restaurantes = Model.restaurante.Action.getAll();
        if (!this.descuento_zona_poligonal) return <SLoad />
        if (!this.state.zonas_poligonales) return <SLoad />
        return <SView col={"xs-12"}>
            <SHr />
            <SList
                data={this.descuento_zona_poligonal}
                filter={a => a.estado != 0}
                render={(obj) => {
                    console.log(obj);
                    var restaurante = this.state.zonas_poligonales[obj.key_zona_poligonal]
                    var zonaPadre = this.state.zonas_poligonales[restaurante?.key_zona_poligonal ?? ""]
                    return <SView card col={"xs-12"} row style={{
                        padding: 8
                    }}>
                        <SView style={{ width: 16, height: 16, borderRadius: 4, backgroundColor: restaurante?.color ?? STheme.color.card, }}>

                        </SView>
                        <SView width={8} />
                        <SView col={"xs-11"} row>

                            <SText fontSize={16}>{!zonaPadre ? restaurante?.nombre : zonaPadre.nombre + " - " + restaurante?.nombre}</SText>
                        </SView>
                        <SView flex style={{ alignItems: "flex-end" }} center
                            onPress={() => {
                                SPopup.confirm({
                                    title: "Eliminar zona",
                                    message: "¿Seguro que desea remover la zona?",
                                    onPress: () => {
                                        obj.estado = 0;
                                        Model.descuento_zona_poligonal.Action.editar({
                                            data: obj,
                                            key_usuario: Model.usuario.Action.getKey()
                                        })
                                    }
                                })
                            }}
                        >
                            <SIcon name={'Delete'} width={20} height={20} />
                        </SView>
                    </SView>
                }} />
        </SView>
    }
    $footer() {
        return <SView col={"xs-12"}>
            <SHr />
            <SText card padding={16} width={200} center onPress={this.registrar_restaurante.bind(this)}>AGREGAR ZONAS</SText>
            {this.getZonas()}
        </SView>
        // return <DescuentosHijos data={this.data}/>
    }
}
export default connect(index);