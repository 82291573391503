import { Text, View } from 'react-native'
import React, { Component } from 'react'
import { SHr, SLoad, SMapView, SNavigation, SView } from 'servisofts-component'

export default class MapaDelRestaurante extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ubicacion: { latitude: this.props.restaurante.latitude, longitude: this.props.restaurante.longitude }
        }
    }



    render() {
        if (!this.props.restaurante) return <SLoad />

        return (
            <SView col={"xs-12"} card height={400} width={400}
                onPress={() => {
                    SNavigation.navigate("/restaurante/mapa", {
                        callback: (resp) => {
                            console.log("esta es la respuesta",resp)
                            this.state.ubicacion = resp;
                            this.setState({ ...this.state })
                            if(this.map){
                                this.map.animateToRegion({
                                    latitude:resp.latitude ?? -17.783161833771743,
                                    longitude:resp.longitude ?? -63.182117714439464,
                                    latitudeDelta: 0.0009,
                                    longitudeDelta: 0.0009,
                                })
                            }
                            // if(this.marker){
                            //     this.marker.setCoordinate(resp);
                            // }
                        },
                        latitude: this.state?.ubicacion?.latitude,
                        longitude: this.state?.ubicacion?.longitude,
                    });
                }}
            >
                <SMapView ref={ref => this.map = ref}
                    initialRegion={{
                        latitude: this.state?.ubicacion?.latitude,
                        longitude: this.state?.ubicacion?.longitude,
                        latitudeDelta: 0.0009,
                        longitudeDelta: 0.0009,
                    }}
                >
                    <SMapView.SMarker key={"a"} latitude={this.state.ubicacion.latitude} longitude={this.state.ubicacion.longitude} />
                </SMapView>
            </SView>
        )
        // }
    }
}