import { SPage } from "servisofts-component";

import root from "./root";
import cupones from "./cupones"
import cliente_frecuencia_de_compra from "./cliente_frecuencia_de_compra"
import ticket_promedio_tapekes from "./ticket_promedio_tapekes"
import ticket_promedio_productos from "./ticket_promedio_productos"
import  data_usuario_edad_genero from "./data_usuario_edad_genero"
import  lugares_pedidos_cliente_puntual from "./lugares_pedidos_cliente_puntual"
import promo_heroe_tapeke from "./promo_heroe_tapeke"
import medallas_asfi_nuevo from "./medallas_asfi_nuevo"

export default SPage.combinePages("cliente",
    {
        "": root,
        cupones,
        cliente_frecuencia_de_compra,
        ticket_promedio_tapekes,
        ticket_promedio_productos,
        data_usuario_edad_genero,
        promo_heroe_tapeke,
        lugares_pedidos_cliente_puntual,
        medallas_asfi_nuevo
    }
)

export const BuildCustomHeader = (data: any[], props: { exclude?: string[], }) => {

    let headers: any = [
        // { key: "index", label: "#" }
    ]
    if (data) {
        if (!Array.isArray(data)) {
            data = Object.values(data);
        }
        if (data.length > 0) {
            let first = data[0];
            Object.keys(first).map((k) => {
                if (props?.exclude) {
                    if (props.exclude.indexOf(k) > -1) {
                        return;
                    }
                }

                headers.push({
                    key: k + "-",
                    width: 100,
                })
            })
        }
    }
    return headers;
}