import DPA, { connect } from 'servisofts-page';
import { Parent } from '.';
import { SNavigation, SPopup } from 'servisofts-component';
import CryptoJS from 'crypto-js';
import Model from '../../Model';
import DatosDocumentosEditar from './Components/DatosDocumentosEditar';

class index extends DPA.edit {
    constructor(props) {
        super(props, {
            Parent: Parent,
            excludes: ["Nombres","Apellidos","Correo","Telefono","CI","CI_EXPE","enable", "tester"]
        });
    }
    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "edit" })
    }
    $getData() {
        return Parent.model.Action.getByKey(this.pk);
    }
    $inputs() {
        var inputs = super.$inputs();
        delete inputs["image_profile"]
        inputs["Password"].type = "password"
        // inputs["Password"].values = CryptoJS.MD5(inputs["Password"].values).toString();

        return inputs;
    }

    $onSubmit(data) {
        data.Password = CryptoJS.MD5(data.Password).toString();
        delete data["image_profile"];
        Parent.model.Action.editar({
            data: {
                ...this.data,
                ...data
            },
            key_usuario: Model.usuario.Action.getKey()
        }).then((resp) => {
            SNavigation.goBack();
            // this.presolve(this.pk)
        }).catch(e => {
            console.error(e);
        })
    }
}

export default connect(index);