import { Parent } from "."
import Model from '../../Model';
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SDate, SMath, SNavigation, SText, SButtom, SPopup, SLoad, SPage, STable2 } from 'servisofts-component';
import SSocket from 'servisofts-socket'

class index extends Component {
    state = {
        data: {}
    }
    constructor(props) {
        super(props);
        this.pk = SNavigation.getParam("pk");
    }

    componentDidMount() {
        this.setState({ loading: true, error: "" })
        SSocket.sendPromise({
            component: "pedido",
            type: "reporte_pedido_tabla",
        }).then(e => {
            this.setState({ loading: false, error: "", data: e.data })
            console.log(e.data);
        }).catch(e => {
            SPopup.alert(e.error)
            this.setState({ loading: false, error: e.error })
        })
    }

    renderIncentivos(incentivo) {
        if (incentivo && incentivo?.length > 0) {
            let totalIncentivo;
            totalIncentivo = this.totalIncentivos(incentivo);
            return "Bs. " + SMath.formatMoney(totalIncentivo)
        } else {
            return "No hay incentivos"
        }
    }

    totalIncentivos(incentivos) {
        if (incentivos) {
            return incentivos.reduce((acumulador, objetoActual) => {
                return acumulador + objetoActual.monto;
            }, 0);
        }
        return 0;
    }

    renderDescuentos(descuento, tipoDescuento) {
        if (descuento) {
            switch (tipoDescuento) {
                case "delivery":
                    return "Bs. " + SMath.formatMoney(parseFloat(descuento ?? 0))

                case "producto":
                    return "Bs. " + SMath.formatMoney(parseFloat(descuento ?? 0))
                    break;

                case "tapeke":
                    return "Bs. " + SMath.formatMoney(parseFloat(descuento ?? 0))
                    break;

                default:
                    break;
            }
        } else {
            return `Sin Desc ${tipoDescuento}`
        }
    }

    calcularGananciaPartner(pedido) {
        if (pedido) {
            let totalPedido = (pedido.total_tapekes ?? 0) + (this.totalProducto(pedido.pedido_producto) ?? 0) + (this.totalSubProducto(pedido.pedido_producto) ?? 0);

            if (totalPedido < 0) {
                console.error(`${pedido.key} - ${totalPedido} es calculo menor a 0`);
            }

            return totalPedido
        }
    }

    cantidadProductos(pedido_producto) {
        let cantidad = 0;
        if (pedido_producto) {
            pedido_producto.forEach(p => {
                cantidad += p.cantidad;
            });
        }
        return cantidad;
    }

    totalProducto(pedido_producto) {
        let totalProducto = 0;
        if (pedido_producto) {
            Object.values(pedido_producto).map((p) => {
                totalProducto += p.precio * p.cantidad;
            })
        }
        return totalProducto;
    }

    totalSubProducto(pedido_producto) {
        let totalSubProducto = 0;
        if (pedido_producto) {
            Object.values(pedido_producto).map((p) => {
                totalSubProducto = p.monto_total_subproducto_detalle;
            })
        }
        return totalSubProducto;
    }

    deliveryPagadoCliente(pedido) {
        if (pedido) {
            let calculo = (pedido.delivery + parseFloat(this.totalIncentivos(pedido?.incentivos)) - parseFloat(pedido?.total_descuento_delivery ?? 0));
            return calculo;
        }
    }

    totalDescuento(descuento) {
        if (descuento) {
            let totalDescuento = 0;
            descuento.map(d => {
                totalDescuento += d?.total_descuento_delivery + descuento?.total_descuento_producto + descuento?.total_descuento_tapeke;
            })
            return totalDescuento;
        }
    }

    totalDescuentoIteam(pedido_producto) {
        let totalDescuentoIteam = 0;
        if (pedido_producto) {
            Object.values(pedido_producto).map((prod) => {
                if (prod.precio_sin_descuento) {
                    totalDescuentoIteam += (prod.cantidad * prod.precio_sin_descuento) - (prod.cantidad * prod.precio)
                }
            });
        }
        return totalDescuentoIteam;
    }

    totalPagoCliente(pedido) {
        if (pedido) {
            let totalPedido = (pedido.total_tapekes ?? 0) + (this.totalProducto(pedido.pedido_producto) ?? 0) + (this.totalSubProducto(pedido.pedido_producto) ?? 0);

            let montoCupon = 0;
            if (!!pedido.cupon_usuario) montoCupon = (pedido.cupon_usuario?.monto ?? 0);

            let delivery = ((pedido.delivery ?? 0) + (this.totalIncentivos(pedido.incentivos) ?? 0))

            let totalPagado = ((totalPedido + delivery) - montoCupon) - ((this.totalDescuento(pedido.descuentos) ?? 0) + this.totalDescuentoIteam(pedido.pedido_producto));
            if (isNaN(totalPagado)) {
                // console.log("totalPagado", totalPagado);
            }
            return totalPagado;
        }
    }

    calcularTotales() {
        if (!this.data) return;
        let totales = {
            totalTapeke: 0,
            totalProducto: 0,
            totalSubProducto: 0,
            total: 0,

            totalDescuentoIteam: 0,
        }

        totales.totalTapeke = (this.data?.cantidad * this.data?.precio);

        if (!!this.data?.pedido_producto) {
            Object.values(this.data?.pedido_producto).map((prod) => {
                if (prod.precio_sin_descuento) {
                    totales.totalProducto += (prod.cantidad * prod.precio_sin_descuento)
                    totales.totalDescuentoIteam += (prod.cantidad * prod.precio_sin_descuento) - (prod.cantidad * prod.precio)
                } else {
                    totales.totalProducto += (prod.cantidad * prod.precio)
                }
                if (prod.sub_productos) {
                    Object.values(prod.sub_productos).map((sub) => {
                        if (sub.sub_producto_detalle) {
                            Object.values(sub.sub_producto_detalle).map((subDet) => {
                                totales.totalSubProducto += (subDet?.precio * subDet?.cantidad) * prod?.cantidad;
                            });
                        }
                    });
                }
            });
        }

        totales.total = totales.totalTapeke + totales.totalProducto + totales.totalSubProducto;

        return totales;
    }

    $allowAccess() {
        return Model.usuarioPage.Action.getPermiso({ url: Parent.path, permiso: "table" })
    }

    renderTable() {
        let users = Model.usuario.Action.getAll()
        // if (!users) return <SLoad />
        if (!this.state.data) return <SLoad />

        return <STable2
            header={
                [
                    { key: "index", label: "#" },
                    { key: "key", label: "Detalle Pedido", width: 100, component: (key) => <SButtom onPress={() => SNavigation.navigate("/pedido/profile", { pk: key })}>VER</SButtom> },
                    { key: "fecha", label: "Fecha de Entrega o Fecha Horario", order: "desc", width: 200 },
                    { key: "fecha_on", label: "Fecha de Pedido", width: 100, render: a => new SDate(a).toString("yyyy-MM-dd") },
                    { key: "-/fecha_on", label: "Hora de Pedido", width: 80, render: a => new SDate(a).toString("hh:mm") },
                    { key: "-/key", label: "Key Pedido", width: 250 },
                    { key: "state", label: "Estado Pedido", width: 80 },
                    { key: "key_usuario", label: "Usuario", width: 200, render: a => a ? users[a]?.Nombres + " " + users[a]?.Apellidos : "No se pillo el usuario" },

                    { key: "nombre_restaurante", label: "Restaurante", width: 200 },
                    { key: "nombre_representante", label: "Nombre Representante Partner", width: 200 },

                    { key: "key_conductor", label: "Driver", width: 200, render: a => a ? users[a]?.Nombres + " " + users[a]?.Apellidos : "No se pillo el usuario" },

                    { key: "tipo_pago", label: "Tipo Pago", width: 160 },

                    { key: "cantidad", label: "Cantidad Tapekes", width: 100 },
                    { key: "precio_pack", label: "Precio Unitario Tapekes", width: 120, render: a => "Bs. " + SMath.formatMoney(a, 2) },

                    /* ----------------------------------------------------------------------------------------------------------------------------------- */

                    { key: "total_tapekes", label: "Total Tapeke", width: 120, render: a => "Bs. " + SMath.formatMoney(a, 2) },

                    { key: "-/", label: "Cantidad Producto", width: 120, render: a => this.cantidadProductos(a.pedido_producto) },

                    { key: "pedido_producto", label: "Total Productos", width: 120, render: a => "Bs. " + SMath.formatMoney(this.totalProducto(a, 2)) },

                    { key: "-/pedido_producto", label: "Total SubProductos", width: 120, render: a => "Bs. " + SMath.formatMoney(this.totalSubProducto(a, 2)) },

                    { key: "-/-", label: "Ganancia Partner", width: 120, render: a => this.calcularGananciaPartner(a) },
                    { key: "comision_restaurante", label: "Comisión Tapeke por venta", width: 140, render: a => "Bs. " + SMath.formatMoney(a, 2) },

                    /* ------------------------------------------------------------------------------------------------------------------------------------ */


                    { key: "comision_driver", label: "Ganancia Driver", width: 120, render: a => a ? "Bs. " + SMath.formatMoney(a, 2) : "Recoger del lugar" },
                    { key: "comision_delivery", label: "Comision Tapeke por delivery", width: 150, render: a => a ? "Bs. " + SMath.formatMoney(a, 2) : "Recoger del lugar" },
                    { key: "delivery", label: "Delivery", width: 120, render: a => a ? "Bs. " + SMath.formatMoney(a, 2) : "Recoger del lugar" },

                    { key: "incentivos", label: "Total Incentivos Driver", width: 150, render: a => a ? this.renderIncentivos(a) : "Recoger del lugar" },

                    { key: "total_descuento_delivery", label: "Descuento Delivery", width: 120, render: a => this.renderDescuentos(a, "delivery") },
                    { key: "total_descuento_producto", label: "Descuento Producto", width: 120, render: a => this.renderDescuentos(a, "producto") },
                    { key: "total_descuento_tapeke", label: "Descuento Tapeke", width: 120, render: a => this.renderDescuentos(a, "tapeke") },


                    { key: "-/-", label: "Delivery pagado por Cliente", width: 120, render: a => "Bs. " + SMath.formatMoney(this.deliveryPagadoCliente(a)) },

                    { key: "-/-/-", label: "Total pagado por el Cliente", width: 130, render: a => "Bs. " + SMath.formatMoney(this.totalPagoCliente(a)) },
                ]

            }
            data={this.state.data} />
    }

    render() {
        return <SPage
            title={`Reporte de Pedidos`}
            disableScroll
        >{this.renderTable()}</SPage>
    }
}

const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);