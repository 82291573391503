import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SHr, SIcon, SImage, SLoad, SNavigation, SPage, SText, STheme, SView, SInput, SPopup } from 'servisofts-component';
import Model from '../../Model';
import { GeolocationMapSelect } from 'servisofts-rn-geolocation'
import Btn from '../../Components/Btn';
import PopupAutoCompleteDireccion from './profile/Components/PopupAutoCompleteDireccion';

class index extends Component {
    constructor(props) {
        super(props);

        this.callback = SNavigation.getParam("callback");
        this.hiddeDescripcion = SNavigation.getParam("hiddeDescripcion");
        this.hiddeReferencia = SNavigation.getParam("hiddeReferencia");

        this.params = SNavigation.getAllParams() ?? {};
    }

    getImput() {
        if (this.hiddeDescripcion) return null;
        return <SView col={"xs-12"} >
            <SInput fontSize={16} placeholder={"Nombre de la Ubicación"}
                isRequired={true}
                height={48}
            />
        </SView>
    }

    getComponentBottom() {
        return <SView col={"xs-12 sm-10 md-8 lg-6 xl-4"} height={280} row center>
            <SHr height={10} />
            <SView col={"xs-11"} center row border={'transparent'}>

                <SView col={"xs-12"}>
                    <SHr height={5} />
                    <SInput
                        height={48}
                        style={{
                            backgroundColor: STheme.color.card + 1,
                            fontSize: 14
                        }}
                        editable={false}
                        placeholder={"Busca una dirección!"}
                        value={this.state?.data?.direccion ? `${this.state?.data?.direccion.substring(0, 40)}${this.state?.data?.direccion.length > 40 ? "..." : ""}` : ""}
                        onPress={() => {
                            SPopup.open({
                                key: "autocomplete",
                                content:
                                    <PopupAutoCompleteDireccion region={this.state.data} callback={(resp) => {
                                        SPopup.close("autocomplete");
                                        this.state.data = resp;
                                        console.log(resp)
                                        // this.state.region = resp;
                                        this.map.getMap().animateToRegion({
                                            ...resp,
                                            latitudeDelta: 0.01,
                                            longitudeDelta: 0.01
                                        }, 1000);

                                        this.setState({ ...this.state });
                                    }} />
                            });
                        }}
                        iconR={<SIcon name={"SearchTapeke"} width={40} height={18} fill={STheme.color.primary} />}
                    />
                </SView>
            </SView>
            <SHr />
            <SView col={"xs-8.8"} row center border={'transparent'}  >
                <Btn fontSize={16} onPress={() => {
                    var data = {
                        latitude: this.state?.data?.latitude,
                        longitude: this.state?.data?.longitude,
                        direccion: this.state?.data?.direccion,
                    }
                    if (this.callback) {
                        this.callback(data);
                        SNavigation.goBack();
                    }
                }}>ELEGIR ESTA UBICACIÓN</Btn>
            </SView>
            <SHr height={10} />
        </SView>
    }
    render() {
        return (
            <SPage center disableScroll>
                <GeolocationMapSelect
                    initialRegion={{
                        latitude: this.params?.latitude ?? -17.783286905448936,
                        longitude: this.params?.longitude ?? -63.18212466708234,
                        latitudeDelta: 0.0009,
                        longitudeDelta: 0.0009,
                    }}
                    ref={(map) => this.map = map}
                    icon={<SIcon name="MarcadorMapa" width={30} height={30} />}
                    onChange={(evt) => {
                        this.setState({ data: evt })
                    }} />
                {this.getComponentBottom()}
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(index);